import { Helmet } from 'react-helmet/es/Helmet'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { TableBottomPaginationBlock } from '../../components/Common/Table'
import {
  Button,
  message,
  Pagination,
  Popconfirm,
  Tooltip,
  Space,
  Col,
  Row,
  Input,
} from 'antd'
import uuid from 'uuid'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import {
  DeleteOutlined,
  QuestionCircleOutlined,
  CloseCircleOutlined,
  FilterOutlined,
} from '@ant-design/icons'
import PageTitle from '../../components/PageTitle'
import { inject, observer } from 'mobx-react'
import signAccountStore from '../../stores/signAccountStore'
import EditSignAccountModal from './EditSignAccountModal/EditSignAccountModal'
import TableComponent from '../../components/Common/TableComponent'
import { ACL_ACTION_TYPE } from '../../constants'
import AccessControlAction from '../../components/AccessControlAction/AccessControlAction'
import {
  FilterButton,
  SearchBar,
} from '../UserAccountManagementPage/UserAcountManagementPageStyled'
import AdvanceFilter from './AdvanceFilter'
const { Search } = Input
const SignAccountPage = props => {
  const { loadingAnimationStore, signAccountStore, history, aclStore } = props

  const { list, totalCount, filter, setFilter } = signAccountStore
  const [isVisibleModal, setIsVisibleModal] = useState(false)

  useEffect(() => {
    load()
    return () => signAccountStore.clear()
  }, [signAccountStore])
  useEffect(() => {
    load()
  }, [filter])
  function load() {
    loadingAnimationStore.showSpinner(true)

    signAccountStore
      .getList(filter.page, filter.size)
      .then(() => {})
      .finally(() => {
        loadingAnimationStore.showSpinner(false)
      })
  }

  const onChangeTable = useCallback(
    (current, size) => {
      if (size !== filter.size) {
        filter.page = 1
        filter.size = size
      } else {
        filter.page = current
      }
      signAccountStore.setFilter(filter)
      load()
    },
    [signAccountStore, filter]
  )

  const handleCloseModal = () => {
    setIsVisibleModal(false)
    signAccountStore.clearDetails()
  }

  const handleDelete = async record => {
    try {
      loadingAnimationStore.showSpinner(true)
      await signAccountStore.delete(record)
      load()
    } catch (error) {
      message.error(error.vi)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const onItemClick = record => {
    // console.log('record', toJS(record))
    signAccountStore.signAccountDetails = record
    setIsVisibleModal(true)
  }

  const tableColumn = [
    // {
    //   title: 'Nhà cung cấp',
    //   render: record => record?.provider,
    //   onCell: record => {
    //     return {
    //       onClick: () => onItemClick(record),
    //     }
    //   },
    // },
    {
      title: 'Người dùng',
      render: record => record?.user_name,
      onCell: record => {
        return {
          onClick: () => onItemClick(record),
        }
      },
    },
    {
      title: 'Tài khoản',
      render: record => record?.account,
      onCell: record => {
        return {
          onClick: () => onItemClick(record),
        }
      },
    },
    {
      title: 'Loại',
      render: record => record?.type,
      onCell: record => {
        return {
          onClick: () => onItemClick(record),
        }
      },
    },
    aclStore.checkAccessControlAction(
      ACL_ACTION_TYPE.delete__TAI_KHOAN_KY_SO
    ) ? (
      {
        title: 'Tác vụ',
        width: 100,
        render: record => (
          <>
            <AccessControlAction
              aclActionType={ACL_ACTION_TYPE.delete__TAI_KHOAN_KY_SO}>
              <Tooltip title={'Xóa'}>
                <Popconfirm
                  onConfirm={() => {
                    handleDelete(record)
                  }}
                  okText={'Đồng ý'}
                  cancelText={'Không'}
                  okType={'danger'}
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  title={'Bạn có muốn xoá tài khoản này?'}>
                  <DeleteOutlined style={{ color: '#fc0000' }} />
                </Popconfirm>
              </Tooltip>
            </AccessControlAction>
          </>
        ),
      }
    ) : (
      <></>
    ),
  ]
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false)

  return (
    <DashboardLayout>
      <Helmet>
        <title>Tài khoản ký số</title>
      </Helmet>

      <PageTitle
        location={props.location}
        title={'Tài khoản ký số'}
        hiddenGoBack>
        <div>
          <AccessControlAction
            aclActionType={ACL_ACTION_TYPE.create__TAI_KHOAN_KY_SO}>
            <Button
              style={{ margin: '0 15px' }}
              type={'primary'}
              onClick={() => {
                signAccountStore.clearDetails()
                setIsVisibleModal(true)
              }}>
              Tạo tài khoản
            </Button>
          </AccessControlAction>
        </div>
      </PageTitle>

      <ContentBlockWrapper>
        <Row style={{ marginBottom: 16 }}>
          <Col span={12}></Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <FilterButton
              onClick={() => setShowAdvanceFilter(!showAdvanceFilter)}
              type={showAdvanceFilter ? 'primary' : 'default'}
              ghost={showAdvanceFilter}>
              {showAdvanceFilter ? (
                <Space>
                  <CloseCircleOutlined />
                  Tắt tìm kiếm nâng cao
                </Space>
              ) : (
                <Space>
                  <FilterOutlined />
                  Tìm kiếm nâng cao
                </Space>
              )}
            </FilterButton>
          </Col>
        </Row>

        {showAdvanceFilter ? (
          <AdvanceFilter filter={filter} setFilter={setFilter} />
        ) : null}
        <TableComponent
          // onRow={record => {
          //   return {
          //     onClick: () => {
          //       // console.log('record', toJS(record))
          //       signAccountStore.signAccountDetails = record
          //       setIsVisibleModal(true)
          //     },
          //   }
          // }}
          rowKey={() => uuid()}
          columns={tableColumn}
          dataSource={list}
          pagination={false}
          // onChange={onChangeTable}
        />
        <TableBottomPaginationBlock>
          <Pagination
            current={filter.page}
            pageSize={filter.size}
            total={totalCount}
            onChange={onChangeTable}
            showSizeChanger={false}
            showLessItems
          />
        </TableBottomPaginationBlock>

        <EditSignAccountModal
          isVisibleModal={isVisibleModal}
          handleCloseModal={handleCloseModal}
        />
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

SignAccountPage.propTypes = {}

export default memo(
  inject(
    'loadingAnimationStore',
    'signAccountStore',
    'aclStore'
  )(observer(SignAccountPage))
)
