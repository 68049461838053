import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { Button, Checkbox, Input, message, Select } from 'antd'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import ACLActionCard from './ACLActionCard'
import { useHistory, useParams } from 'react-router-dom'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent/SpinnerInlineComponent'
import { FormOutlined } from '@ant-design/icons'
import { ACL_TYPE } from '../../constants'
import { systemName } from '../../config'

const { Option } = Select

const AclCreatePage = props => {
  const { aclStore } = props

  let { code: aclCode } = useParams()
  const { aclActionList } = aclStore

  const [nameACL, setNameACL] = useState('')

  const [MISSION_actions, set_MISSION_actions] = useState([])
  const [WORK_actions, set_WORK_actions] = useState([])
  const [WORK_SCHEDULE_actions, set_WORK_SCHEDULE_actions] = useState([])
  const [INTERNAL_DOCUMENT_actions, set_INTERNAL_DOCUMENT_actions] = useState(
    []
  )
  const [INCOMING_actions, set_INCOMING_actions] = useState([])
  const [OUTGOING_actions, set_OUTGOING_actions] = useState([])
  const [PHAT_HANH_VB_actions, set_PHAT_HANH_VB_actions] = useState([])
  const [LIEN_THONG_VB_actions, set_LIEN_THONG_VB_actions] = useState([])
  const [
    LIEN_THONG_INCOMING_actions,
    set_LIEN_THONG_INCOMING_actions,
  ] = useState([])
  const [
    LIEN_THONG_OUTGOING_actions,
    set_LIEN_THONG_OUTGOING_actions,
  ] = useState([])
  const [UBQLV_actions, set_UBQLV_actions] = useState([])
  const [
    TIN_NHAN_LIEN_THONG_actions,
    set_TIN_NHAN_LIEN_THONG_actions,
  ] = useState([])
  const [TIN_NHAN_NOI_BO_actions, set_TIN_NHAN_NOI_BO_actions] = useState([])
  const [PROPOSAL_actions, set_PROPOSAL_actions] = useState([])
  const [PROPOSAL_ASSET_actions, set_PROPOSAL_ASSET_actions] = useState([])
  const [SALARY_actions, set_SALARY_actions] = useState([])
  const [NEWS_actions, set_NEWS_actions] = useState([])
  const [ISO_actions, set_ISO_actions] = useState([])
  const [PHAP_CHE_actions, set_PHAP_CHE_actions] = useState([])
  const [KY_SO_actions, set_KY_SO_actions] = useState([])
  const [TAI_LIEU_DA_KY_actions, set_TAI_LIEU_DA_KY_actions] = useState([])
  const [CHU_KY_CUA_TOI_actions, set_CHU_KY_CUA_TOI_actions] = useState([])
  const [TAI_KHOAN_KY_SO_actions, set_TAI_KHOAN_KY_SO_actions] = useState([])
  const [MY_SALARY_actions, set_MY_SALARY_actions] = useState([])
  const [NHOM_NGUOI_DUNG_actions, set_NHOM_NGUOI_DUNG_actions] = useState([])
  const [NHOM_NHIEM_VU_actions, set_NHOM_NHIEM_VU_actions] = useState([])
  const [PROPOSAL_ADVANCE_actions, set_PROPOSAL_ADVANCE_actions] = useState([])
  const [VAN_BAN_MAU_actions, set_VAN_BAN_MAU_actions] = useState([])
  const [KPI_actions, set_KPI_actions] = useState([])
  const [QLY_PHONG_BAN_actions, set_QLY_PHONG_BAN] = useState([])
  const [CHUC_DANH_actions, set_CHUC_DANH] = useState([])
  const [PKS_RUI_RO_actions, set_PKS_RUI_RO] = useState([])
  const [LOAI_HANH_CHINH_actions, set_LOAI_HANH_CHINH] = useState([])
  const [NHOM_LOAI_HANH_CHINH_actions, set_NHOM_LOAI_HANH_CHINH] = useState([])
  const [BAO_CAO_actions, set_BAO_CAO] = useState([])
  const [QUY_TRINH_MUA_SAM_actions, set_QUY_TRINH_MUA_SAM] = useState([])
  const [MENU_OUTSIDE_actions, set_MENU_OUTSIDE] = useState([])

  const [isCheckAllStatus, setIsCheckAllStatus] = useState(false)
  const [actionListStatus, setActionListStatus] = useState({
    MISSION: false,
    WORK: false,
    WORK_SCHEDULE: false,
    INTERNAL_DOCUMENT: false,
    INCOMING: false,
    OUTGOING: false,
    PHAT_HANH_VB: false,
    LIEN_THONG_VB: false,
    LIEN_THONG_INCOMING: false,
    LIEN_THONG_OUTGOING: false,
    UBQLV: false,
    TIN_NHAN_LIEN_THONG: false,
    TIN_NHAN_NOI_BO: false,
    PROPOSAL: false,
    PROPOSAL_ASSET: false,
    SALARY: false,
    NEWS: false,
    ISO: false,
    PHAP_CHE: false,
    KY_SO: false,
    TAI_LIEU_DA_KY: false,
    CHU_KY_CUA_TOI: false,
    TAI_KHOAN_KY_SO: false,
    MY_SALARY: false,
    QUAN_LY_PHONG_BAN: false,
    NHOM_NGUOI_DUNG: false,
    NHOM_NHIEM_VU: false,
    PROPOSAL_ADVANCE: false,
    VAN_BAN_MAU: false,
    KPI: false,
    CHUC_DANH: false,
    PKS_RUI_RO: false,
    QUY_TRINH_MUA_SAM: false,
  })

  useEffect(() => {
    const allStatusTrue = Object.values(actionListStatus).every(value => value)
    if (allStatusTrue) {
      setIsCheckAllStatus(true)
    } else {
      setIsCheckAllStatus(false)
    }
  }, [actionListStatus])

  useEffect(() => {
    const missionActions = []
    const workActions = []
    const workScheduleActions = []
    const internalDocumentActions = []
    const incomingActions = []
    const outgoingActions = []
    const phatHanhVBActions = []
    const lienThongVBActions = []
    const lienThongIncomingActions = []
    const lienThongOutgoingActions = []
    const ubqlvActions = []
    const tinNhanLienThongActions = []
    const tinNhanNoiBoActions = []
    const proposalActions = []
    const proposalAssetActions = []
    const salaryActions = []
    const newsActions = []
    const isoActions = []
    const phapCheActions = []
    const kySoActions = []
    const taiLieuDaKyActions = []
    const chuKyCuaToiActions = []
    const taiKhoanKySoActions = []
    const mySalaryActions = []
    const nhomNguoiDungActions = []
    const nhomNhiemVuActions = []
    const proposalAdvanceActions = []
    const vanBanMauActions = []
    const kpiActions = []
    const quanlyPhongBanActions = []
    const chucDanhActions = []
    const phieuKiemSoatRuiRoActions = []
    const baoCaoActions = []
    const loaiHanhChinhActions = []
    const nhomLoaiHanhChinhActions = []
    const quyTrinhMuaSamActions = []
    const menuOutsideActions = []
    aclActionList.forEach(item => {
      if (item.tag === ACL_TYPE.MISSION) {
        missionActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.WORK) {
        workActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.WORK_SCHEDULE) {
        workScheduleActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.INTERNAL_DOCUMENT) {
        internalDocumentActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.INCOMING) {
        incomingActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.OUTGOING) {
        outgoingActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.PHAT_HANH_VB) {
        phatHanhVBActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.LIEN_THONG_VB) {
        lienThongVBActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.LIEN_THONG_INCOMING) {
        lienThongIncomingActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.LIEN_THONG_OUTGOING) {
        lienThongOutgoingActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.UBQLV) {
        ubqlvActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.TIN_NHAN_LIEN_THONG) {
        tinNhanLienThongActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.TIN_NHAN_NOI_BO) {
        tinNhanNoiBoActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.PROPOSAL) {
        proposalActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.PROPOSAL_ASSET) {
        proposalAssetActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.SALARY) {
        salaryActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.NEWS) {
        newsActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.ISO) {
        isoActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.PHAP_CHE) {
        phapCheActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.TAI_LIEU_DA_KY) {
        taiLieuDaKyActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.KY_SO) {
        kySoActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.CHU_KY_CUA_TOI) {
        chuKyCuaToiActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.TAI_KHOAN_KY_SO) {
        taiKhoanKySoActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.MY_SALARY) {
        mySalaryActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.NHOM_NGUOI_DUNG) {
        nhomNguoiDungActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.NHOM_NHIEM_VU) {
        nhomNhiemVuActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.PROPOSAL_ADVANCE) {
        proposalAdvanceActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.VAN_BAN_MAU) {
        vanBanMauActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.KPI) {
        kpiActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.QUAN_LY_PHONG_BAN) {
        quanlyPhongBanActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.CHUC_DANH) {
        chucDanhActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.PKS_RUI_RO) {
        phieuKiemSoatRuiRoActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.BAO_CAO) {
        baoCaoActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.LOAI_HANH_CHINH) {
        loaiHanhChinhActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.NHOM_LOAI_HANH_CHINH) {
        nhomLoaiHanhChinhActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.QUY_TRINH_MUA_SAM) {
        quyTrinhMuaSamActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.MENU_OUTSIDE) {
        menuOutsideActions.push(toJS(item))
      }
    })
    set_MISSION_actions(missionActions)
    set_WORK_actions(workActions)
    set_WORK_SCHEDULE_actions(workScheduleActions)
    set_INTERNAL_DOCUMENT_actions(internalDocumentActions)
    set_INCOMING_actions(incomingActions)
    set_OUTGOING_actions(outgoingActions)
    set_PHAT_HANH_VB_actions(phatHanhVBActions)
    set_LIEN_THONG_VB_actions(lienThongVBActions)
    set_LIEN_THONG_INCOMING_actions(lienThongIncomingActions)
    set_LIEN_THONG_OUTGOING_actions(lienThongOutgoingActions)
    set_UBQLV_actions(ubqlvActions)
    set_TIN_NHAN_LIEN_THONG_actions(tinNhanLienThongActions)
    set_TIN_NHAN_NOI_BO_actions(tinNhanNoiBoActions)
    set_PROPOSAL_actions(proposalActions)
    set_PROPOSAL_ASSET_actions(proposalAssetActions)
    set_SALARY_actions(salaryActions)
    set_NEWS_actions(newsActions)
    set_ISO_actions(isoActions)
    set_PHAP_CHE_actions(phapCheActions)
    set_KY_SO_actions(kySoActions)
    set_TAI_LIEU_DA_KY_actions(taiLieuDaKyActions)
    set_CHU_KY_CUA_TOI_actions(chuKyCuaToiActions)
    set_TAI_KHOAN_KY_SO_actions(taiKhoanKySoActions)
    set_MY_SALARY_actions(mySalaryActions)
    set_NHOM_NGUOI_DUNG_actions(nhomNguoiDungActions)
    set_NHOM_NHIEM_VU_actions(nhomNhiemVuActions)
    set_PROPOSAL_ADVANCE_actions(proposalAdvanceActions)
    set_VAN_BAN_MAU_actions(vanBanMauActions)
    set_KPI_actions(kpiActions)
    set_QLY_PHONG_BAN(quanlyPhongBanActions)
    set_CHUC_DANH(chucDanhActions)
    set_PKS_RUI_RO(phieuKiemSoatRuiRoActions)
    set_BAO_CAO(baoCaoActions)
    set_LOAI_HANH_CHINH(loaiHanhChinhActions)
    set_NHOM_LOAI_HANH_CHINH(nhomLoaiHanhChinhActions)
    set_QUY_TRINH_MUA_SAM(quyTrinhMuaSamActions)
    set_MENU_OUTSIDE(menuOutsideActions)
    setActionListStatus(prev => ({
      ...prev,
      MISSION: missionActions?.every(item => item.status),
      WORK: workActions?.every(item => item.status),
      WORK_SCHEDULE: workScheduleActions?.every(item => item.status),
      INTERNAL_DOCUMENT: internalDocumentActions?.every(item => item.status),
      INCOMING: incomingActions?.every(item => item.status),
      OUTGOING: outgoingActions?.every(item => item.status),
      PHAT_HANH_VB: phatHanhVBActions?.every(item => item.status),
      LIEN_THONG_VB: lienThongVBActions?.every(item => item.status),
      LIEN_THONG_INCOMING: lienThongIncomingActions?.every(item => item.status),
      LIEN_THONG_OUTGOING: lienThongOutgoingActions?.every(item => item.status),
      UBQLV: ubqlvActions?.every(item => item.status),
      TIN_NHAN_LIEN_THONG: tinNhanLienThongActions?.every(item => item.status),
      TIN_NHAN_NOI_BO: tinNhanNoiBoActions?.every(item => item.status),
      PROPOSAL: proposalActions?.every(item => item.status),
      PROPOSALASSET: proposalAssetActions?.every(item => item.status),
      SALARY: salaryActions?.every(item => item.status),
      NEWS: newsActions?.every(item => item.status),
      ISO: isoActions?.every(item => item.status),
      PHAP_CHE: phapCheActions?.every(item => item.status),
      KY_SO: kySoActions?.every(item => item.status),
      TAI_LIEU_DA_KY: taiLieuDaKyActions?.every(item => item.status),
      CHU_KY_CUA_TOI: chuKyCuaToiActions?.every(item => item.status),
      TAI_KHOAN_KY_SO: taiKhoanKySoActions?.every(item => item.status),
      MY_SALARY: mySalaryActions?.every(item => item.status),
      QUAN_LY_PHONG_BAN: quanlyPhongBanActions?.every(item => item.status),
      NHOM_NGUOI_DUNG: nhomNguoiDungActions?.every(item => item.status),
      NHOM_NHIEM_VU: nhomNhiemVuActions?.every(item => item.status),
      PROPOSAL_ADVANCE: proposalAdvanceActions?.every(item => item.status),
      VAN_BAN_MAU: vanBanMauActions?.every(item => item.status),
      KPI: kpiActions?.every(item => item.status),
      CHUC_DANH: chucDanhActions?.every(item => item.status),
      PKS_RUI_RO: phieuKiemSoatRuiRoActions?.every(item => item.status),
      BAO_CAO: baoCaoActions?.every(item => item.status),
      QUY_TRINH_MUA_SAM: quyTrinhMuaSamActions?.every(item => item.status),
    }))
  }, [aclActionList])

  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const history = useHistory()

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        if (aclCode) {
          const res = await aclStore.getACLDetail(aclCode)
          setNameACL(res.data.name)
        } else {
          await aclStore.getACLActionList()
        }
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        setIsLoading(false)
      }
    })()
    return () => {
      aclStore.clearActionList()
    }
  }, [])

  const handleSave = async () => {
    const payload = {
      name: nameACL.trim(),
      actions: aclStore.aclActionUpdateList.map(item => ({
        code: item.code,
        status: item.status,
      })),
    }
    try {
      setIsSaving(true)
      if (aclCode) {
        await aclStore.updateACLGroup(aclCode, payload)
        message.success('Lưu thành công!')
      } else {
        await aclStore.createACLGroup(payload)
        history.push('/acl')
        message.success('Tạo phân quyền thành công!')
      }
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsSaving(false)
    }
  }

  const ACLRows = [1, 2, 3, 4, 5, 6, 7]
  const ACLList = [
    {
      type: ACL_TYPE.MISSION,
      name: 'Nhiệm vụ',
      actionList: MISSION_actions,
      row: 1,
    },
    {
      type: ACL_TYPE.WORK,
      name: 'Công việc',
      actionList: WORK_actions,
      row: 1,
    },
    {
      type: ACL_TYPE.WORK_SCHEDULE,
      name: 'Lịch cơ quan',
      actionList: WORK_SCHEDULE_actions,
      row: 1,
    },
    {
      type: ACL_TYPE.INTERNAL_DOCUMENT,
      name: 'Văn bản nội bộ',
      actionList: INTERNAL_DOCUMENT_actions,
      row: 2,
    },
    {
      type: ACL_TYPE.INCOMING,
      name: 'Văn bản đến',
      actionList: INCOMING_actions,
      row: 2,
    },
    {
      type: ACL_TYPE.OUTGOING,
      name: 'Văn bản đi',
      actionList: OUTGOING_actions,
      row: 2,
    },
    {
      type: ACL_TYPE.PHAT_HANH_VB,
      name: 'Phát hành văn bản',
      actionList: PHAT_HANH_VB_actions,
      row: 2,
    },
    {
      type: ACL_TYPE.LIEN_THONG_VB,
      name: `Liên thông văn bản ${systemName}`,
      actionList: LIEN_THONG_VB_actions,
      row: 2,
    },
    {
      type: ACL_TYPE.LIEN_THONG_INCOMING,
      name: 'Liên thông văn bản đến',
      actionList: LIEN_THONG_INCOMING_actions,
      row: 2,
    },
    {
      type: ACL_TYPE.LIEN_THONG_OUTGOING,
      name: 'Liên thông văn bản đi',
      actionList: LIEN_THONG_OUTGOING_actions,
      row: 2,
    },

    {
      type: ACL_TYPE.UBQLV,
      name: 'Liên thông UBQLV',
      actionList: UBQLV_actions,
      row: 2,
    },
    {
      type: ACL_TYPE.TIN_NHAN_LIEN_THONG,
      name: 'Tin nhắn liên thông',
      actionList: TIN_NHAN_LIEN_THONG_actions,
      row: 3,
    },
    {
      type: ACL_TYPE.TIN_NHAN_NOI_BO,
      name: 'Tin nhắn nội bộ',
      actionList: TIN_NHAN_NOI_BO_actions,
      row: 3,
    },
    {
      type: ACL_TYPE.PROPOSAL,
      name: 'Hành chính',
      actionList: PROPOSAL_actions,
      row: 4,
    },
    {
      type: ACL_TYPE.PROPOSAL_ASSET,
      name: 'Quản lý văn phòng phẩm',
      actionList: PROPOSAL_ASSET_actions,
      row: 4,
    },
    // {
    //   type: ACL_TYPE.LOAI_HANH_CHINH,
    //   name: 'Loại hành chính',
    //   actionList: LOAI_HANH_CHINH_actions,
    //   row: 4,
    // },
    // {
    //   type: ACL_TYPE.NHOM_LOAI_HANH_CHINH,
    //   name: 'Nhóm loại hành chính',
    //   actionList: NHOM_LOAI_HANH_CHINH_actions,
    //   row: 4,
    // },
    {
      type: ACL_TYPE.SALARY,
      name: 'Duyệt lương',
      actionList: SALARY_actions,
      row: 4,
    },
    {
      type: ACL_TYPE.NEWS,
      name: 'Thông báo chung',
      actionList: NEWS_actions,
      row: 4,
    },
    {
      type: ACL_TYPE.ISO,
      name: 'Tài liệu ISO',
      actionList: ISO_actions,
      row: 4,
    },
    {
      type: ACL_TYPE.PHAP_CHE,
      name: 'Văn bản pháp chế',
      actionList: PHAP_CHE_actions,
      row: 4,
    },
    {
      type: ACL_TYPE.KY_SO,
      name: 'Ký số điện tử',
      actionList: KY_SO_actions,
      row: 5,
    },
    {
      type: ACL_TYPE.TAI_LIEU_DA_KY,
      name: 'Tài liệu đã ký',
      actionList: TAI_LIEU_DA_KY_actions,
      row: 5,
    },
    {
      type: ACL_TYPE.CHU_KY_CUA_TOI,
      name: 'Chữ ký của tôi',
      actionList: CHU_KY_CUA_TOI_actions,
      row: 5,
    },
    {
      type: ACL_TYPE.TAI_KHOAN_KY_SO,
      name: 'Tài khoản ký số',
      actionList: TAI_KHOAN_KY_SO_actions,
      row: 5,
    },
    {
      type: ACL_TYPE.MY_SALARY,
      name: 'Lương của tôi',
      actionList: MY_SALARY_actions,
      row: 6,
    },
    {
      type: ACL_TYPE.QUAN_LY_PHONG_BAN,
      name: 'Quản lý phòng ban',
      actionList: QLY_PHONG_BAN_actions,
      row: 6,
    },
    {
      type: ACL_TYPE.NHOM_NGUOI_DUNG,
      name: 'Nhóm người dùng',
      actionList: NHOM_NGUOI_DUNG_actions,
      row: 6,
    },
    {
      type: ACL_TYPE.NHOM_NHIEM_VU,
      name: 'Nhóm nhiệm vụ',
      actionList: NHOM_NHIEM_VU_actions,
      row: 6,
    },
    {
      type: ACL_TYPE.PROPOSAL_ADVANCE,
      name: 'Tạm ứng',
      actionList: PROPOSAL_ADVANCE_actions,
      row: 6,
    },
    {
      type: ACL_TYPE.VAN_BAN_MAU,
      name: 'Văn bản mẫu',
      actionList: VAN_BAN_MAU_actions,
      row: 6,
    },
    {
      type: ACL_TYPE.KPI,
      name: 'KPI',
      actionList: KPI_actions,
      row: 7,
    },
    {
      type: ACL_TYPE.CHUC_DANH,
      name: 'Chức danh',
      actionList: CHUC_DANH_actions,
      row: 7,
    },
    {
      type: ACL_TYPE.PKS_RUI_RO,
      name: 'Phiếu kiểm soát rủi ro',
      actionList: PKS_RUI_RO_actions,
      row: 7,
    },
    {
      type: ACL_TYPE.BAO_CAO,
      name: 'Báo cáo',
      actionList: BAO_CAO_actions,
      row: 7,
    },
    {
      type: ACL_TYPE.QUY_TRINH_MUA_SAM,
      name: 'Quy trình mua sắm',
      actionList: QUY_TRINH_MUA_SAM_actions,
      row: 4,
    },
    {
      type: ACL_TYPE.MENU_OUTSIDE,
      name: 'Menu hệ thống',
      actionList: MENU_OUTSIDE_actions,
      row: 4,
    },
  ]

  const onSelectAllChange = checked => {
    setIsCheckAllStatus(checked)
    ACLList.map(item => {
      return {
        ...item,
        actionList: item.actionList.map(action => ({
          ...action,
          status: checked,
        })),
      }
    }).forEach(item => {
      if (item.type === ACL_TYPE.MISSION) {
        set_MISSION_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.WORK) {
        set_WORK_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.WORK_SCHEDULE) {
        set_WORK_SCHEDULE_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.INTERNAL_DOCUMENT) {
        set_INTERNAL_DOCUMENT_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.INCOMING) {
        set_INCOMING_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.OUTGOING) {
        set_OUTGOING_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.PHAT_HANH_VB) {
        set_PHAT_HANH_VB_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.LIEN_THONG_VB) {
        set_LIEN_THONG_VB_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.LIEN_THONG_INCOMING) {
        set_LIEN_THONG_INCOMING_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.LIEN_THONG_OUTGOING) {
        set_LIEN_THONG_OUTGOING_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.UBQLV) {
        set_UBQLV_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.TIN_NHAN_LIEN_THONG) {
        set_TIN_NHAN_LIEN_THONG_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.TIN_NHAN_NOI_BO) {
        set_TIN_NHAN_NOI_BO_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.PROPOSAL) {
        set_PROPOSAL_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.PROPOSAL_ASSET) {
        set_PROPOSAL_ASSET_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.SALARY) {
        set_SALARY_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.NEWS) {
        set_NEWS_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.ISO) {
        set_ISO_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.PHAP_CHE) {
        set_PHAP_CHE_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.TAI_LIEU_DA_KY) {
        set_KY_SO_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.KY_SO) {
        set_TAI_LIEU_DA_KY_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.CHU_KY_CUA_TOI) {
        set_CHU_KY_CUA_TOI_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.TAI_KHOAN_KY_SO) {
        set_TAI_KHOAN_KY_SO_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.MY_SALARY) {
        set_MY_SALARY_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.NHOM_NGUOI_DUNG) {
        set_NHOM_NGUOI_DUNG_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.NHOM_NHIEM_VU) {
        set_NHOM_NHIEM_VU_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.PROPOSAL_ADVANCE) {
        set_PROPOSAL_ADVANCE_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.VAN_BAN_MAU) {
        set_VAN_BAN_MAU_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.KPI) {
        set_KPI_actions(item.actionList)
      }
      if (item.type === ACL_TYPE.QUAN_LY_PHONG_BAN) {
        set_QLY_PHONG_BAN(item.actionList)
      }
      if (item.type === ACL_TYPE.CHUC_DANH) {
        set_CHUC_DANH(item.actionList)
      }
      if (item.type === ACL_TYPE.PKS_RUI_RO) {
        set_PKS_RUI_RO(item.actionList)
      }
      if (item.type === ACL_TYPE.BAO_CAO) {
        set_BAO_CAO(item.actionList)
      }
      if (item.type === ACL_TYPE.LOAI_HANH_CHINH) {
        set_LOAI_HANH_CHINH(item.actionList)
      }
      if (item.type === ACL_TYPE.NHOM_LOAI_HANH_CHINH) {
        set_NHOM_LOAI_HANH_CHINH(item.actionList)
      }
      if (item.type === ACL_TYPE.QUY_TRINH_MUA_SAM) {
        set_QUY_TRINH_MUA_SAM(item.actionList)
      }
      if (item.type === ACL_TYPE.MENU_OUTSIDE) {
        set_MENU_OUTSIDE(item.actionList)
      }
    })
  }

  return (
    <DashboardLayout>
      <Helmet>
        <title>Thiết lập phân quyền | Thiết lập phân quyền</title>
      </Helmet>
      <PageTitle
        location={props.location}
        title={aclCode ? 'Cập nhật phân quyền' : 'Tạo mới phân quyền'}
        showTitle={true}
      />
      <ContentBlockWrapper
        style={{
          display: isLoading ? 'none' : 'block',
          minHeight: 'calc(100vh - 120px)',
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <h2>Thiết lập phân quyền</h2>
          <Button
            loading={isSaving}
            onClick={handleSave}
            type={'primary'}
            style={{ minWidth: 130 }}
            icon={<FormOutlined />}>
            LƯU LẠI
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            gap: 12,
            marginTop: 6,
            marginBottom: 20,
            alignItems: 'center',
          }}>
          <div>Tên quyền thao tác:</div>
          <Input
            value={nameACL}
            style={{ width: 250 }}
            placeholder={'Nhập tên quyền...'}
            onChange={e => setNameACL(e.target.value)}
          />
          <div>Chọn tất cả:</div>
          <Checkbox
            checked={isCheckAllStatus}
            onChange={e => {
              onSelectAllChange(e.target.checked)
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
          {ACLRows.map(row => {
            return (
              <div
                key={row}
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 30,
                }}>
                {ACLList.filter(item => item.row === row).map((item, i) => (
                  <ACLActionCard
                    key={i}
                    title={item.name}
                    actionList={item.actionList}
                    type={item.type}
                    setActionListStatus={setActionListStatus}
                  />
                ))}
              </div>
            )
          })}
        </div>
      </ContentBlockWrapper>
      {isLoading && (
        <ContentBlockWrapper
          style={{ position: 'relative', height: 'calc(100vh - 180px)' }}>
          <SpinnerInlineComponent
            noBackground
            style={{ borderRadius: 8 }}
            sizeSpin={'small'}
            isLoading={true}
            alignItems
            tip={'Đang tải dữ liệu...'}
          />
        </ContentBlockWrapper>
      )}
    </DashboardLayout>
  )
}

AclCreatePage.propTypes = {}

export default inject('aclStore')(observer(AclCreatePage))
