import React, {useState} from 'react'
// Ant design
import {
    PlusCircleOutlined,
    SmileOutlined,
    UploadOutlined,
} from '@ant-design/icons'
import {
    Button,
    Dropdown,
    Form,
    Image,
    Input,
    Menu,
    Modal,
    Radio,
    Select, 
    Space,
    Upload,
    message,
    notification,
} from 'antd'
// Styled Components
import styled from 'styled-components'
import {ImgSignatureDemo} from './CreateSignatureModalStyled'
// Components
import {inject, observer} from 'mobx-react'
import {DIGITAL_SIGN_PROVIDER, DIGITAL_TYPE_SIGN} from '../../../constants'
import SignatureCanvasModal from './SignatureCanvasModal/SignatureCanvasModal'

const ModalWrapper = styled(Modal)`
    .ant-modal-content {
        min-height: 300px;
    }
`

const TypeSignatureStyle = {
    display: 'inline-block',
    minWidth: 150,
    lineHeight: '35px',
}

const CreateSignatureModal = props => {
    const {
        isCreateSignatureModalVisible,
        handleCloseCreateSignatureModal,
        signedDocumentStore,
        loadingAnimationStore,
        fileStore,
        authenticationStore,
    } = props

    const [typeSignatureActive, setTypeSignatureActive] = useState(null)
    const [imgSignature, setImgSignature] = useState(null)
    const [
        isVisibleSignatureCanvasModal,
        setIsVisibleSignatureCanvasModal,
    ] = useState(false)
    const [fileSignature, setFileSignature] = useState(null)
    const {currentUser} = authenticationStore

    const onChange = e => {
        setTypeSignatureActive(e.target.value)
    }

    const handleCloseModal = () => {
        handleCloseCreateSignatureModal()
        setTypeSignatureActive(null)
    }

    const handleCancelSignatureCanvas = () => {
        setIsVisibleSignatureCanvasModal(false)
    }

    const handleCreatedSuccess = () => {
        refreshList()
    }

    const onChangeUploadImg = file => {
        if (file.file.type !== 'image/png') {
            message.warning('File được chọn phải file ảnh PNG!')
            return
        }
        setFileSignature(file.file)
        const imgBlob = new Blob([file.file], {type: 'image/png'})
        const url_signature = URL.createObjectURL(imgBlob)
        setImgSignature(url_signature)
    }

    const handleCreateSignature = async value => {
        if (selectedSignature === '') {
            message.error('Bạn phải nhập loại chữ ký trước khi lưu')
            return 0;
        }
        const formData = new FormData()
        formData.append('file', fileSignature, value.signature_name)

        try {
            loadingAnimationStore.showSpinner(true)

            //upload file
            const uploadResponse = await fileStore.uploadFile(formData)
            const fileId = uploadResponse.data.file_id
            const fileName = value.signature_name
            console.log('uploadedFile', fileId)

            //sign
            const data = {
                file_id: fileId,
                file_name: fileName,
                provider: DIGITAL_SIGN_PROVIDER,
                type_sign: DIGITAL_TYPE_SIGN,
                type_signature: selectedSignature,
            }
            const createSignatureBySendFileIdResponse = await signedDocumentStore.createSignatureBySendFileId(
                data
            )
            message.success('Tạo chữ ký thành công')
            refreshList()
        } catch (error) {
            console.log(error)
            message.error(error.vi)
        } finally {
            loadingAnimationStore.showSpinner(false)
            handleCloseCreateSignatureModal()
        }

        setTypeSignatureActive(null)
        setImgSignature(null)
    }

    const handleCreateTextSignature = () => {
        notification.open({
            message: <b>Thông báo</b>,
            description: 'Chưa có chức năng tạo chữ ký văn bản!',
            placement: 'topLeft',
            duration: 2,
            icon: <SmileOutlined style={{color: '#d63031'}}/>,
        })
    }

    const handleCancelCreateImageSignature = () => {
        setTypeSignatureActive(null)
        setImgSignature(null)
    }

    const refreshList = async () => {
        if (currentUser) {
            loadingAnimationStore.showSpinner(true)
            await signedDocumentStore.getSignatureList({
                user_name: currentUser.username,
            })
            loadingAnimationStore.showSpinner(false)
        }
    }

    const [selectedSignature, setSelectedSignature] = useState('MAIN_SIGN') // Default button text // Default button text

    const handleMenuClick = e => {
        setSelectedSignature(e)
    }

    return (
        <ModalWrapper
            title={
                <span style={{fontWeight: 'bold', color: ' rgb(16, 136, 231)'}}>
          Chọn loại chữ ký
        </span>
            }
            visible={isCreateSignatureModalVisible}
            onCancel={handleCloseModal}
            footer={null}>
            <Radio.Group onChange={onChange} value={typeSignatureActive}>
                <Space direction="vertical" size={30}>
                    <Radio value={1}>
                        <span style={TypeSignatureStyle}>Chữ ký tay</span>
                        {typeSignatureActive === 1 && (
                            <Button
                                icon={<PlusCircleOutlined/>}
                                type={'primary'}
                                style={{height: 30}}
                                onClick={() => setIsVisibleSignatureCanvasModal(true)}>
                                Tạo
                            </Button>
                        )}
                    </Radio>
                    {/*Chữ ký văn bản*/}
                    {/*<Radio value={2}>*/}
                    {/*  <span style={TypeSignatureStyle}>*/}
                    {/*    Chữ ký văn bản*/}
                    {/*  </span>*/}
                    {/*  {*/}
                    {/*    typeSignatureActive === 2 &&*/}
                    {/*    <Button*/}
                    {/*      icon={<PlusCircleOutlined />}*/}
                    {/*      onClick={handleCreateTextSignature}*/}
                    {/*      type={'primary'}*/}
                    {/*      style={{ height: 30 }}>*/}
                    {/*      Tạo*/}
                    {/*    </Button>*/}
                    {/*  }*/}
                    {/*</Radio>*/}
                    <Radio value={3}>
                        <span style={TypeSignatureStyle}>Chữ ký hình ảnh</span>
                        {typeSignatureActive === 3 && (
                            <Upload
                                showUploadList={false}
                                beforeUpload={() => false}
                                onChange={onChangeUploadImg}>
                                <Button
                                    icon={<UploadOutlined/>}
                                    type={'primary'}
                                    style={{height: 30}}>
                                    {imgSignature ? 'Chọn ảnh khác' : 'Chọn ảnh'}
                                </Button>
                            </Upload>
                        )}
                    </Radio>
                </Space>
            </Radio.Group>
            {imgSignature && typeSignatureActive === 3 && (
                <ImgSignatureDemo>
                    <Form layout="vertical" onFinish={handleCreateSignature}>
                        <Form.Item
                            label="Tên mẫu chữ ký"
                            name="signature_name"
                            rules={[{required: true, message: 'Hãy nhập tên mẫu chữ ký!'}]}>
                            <Input placeholder={'Nhập tên mẫu chữ ký'}/>
                        </Form.Item>
                        <Form.Item
                            name="type_signature"
                            label="Loại chữ ký"
                            rules={[{required: true, message: 'Hãy nhập loại chữ ký!'}]}>
                            <Select
                                allowClear
                                // showSearch
                                value={selectedSignature}
                                onChange={handleMenuClick}
                                defaultValue={''}>
                                <Option value="">
                                        <span style={{color: 'rgba(0,0,0,0.3)'}}>
                                            Vui lòng chọn loại chữ ký{' '}
                                        </span>
                                </Option>
                                <Option value="FLASH_SIGN">Chữ ký nháy</Option>
                                <Option value="MAIN_SIGN">Chữ ký chính</Option>
                                <Option value="CIRCLE_SIGN">Dấu mộc</Option>
                            </Select>
                        </Form.Item>
                        <div className={'img-signature'} style={{marginTop: '10px'}}>
                            <Image src={imgSignature} alt="signature"/>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button
                                danger
                                style={{marginRight: 8}}
                                onClick={handleCancelCreateImageSignature}>
                                Hủy bỏ
                            </Button>
                            <Button type={'primary'} htmlType={'submit'}>
                                Lưu chữ ký
                            </Button>
                        </div>
                    </Form>
                </ImgSignatureDemo>
            )}
            <SignatureCanvasModal
                isVisibleSignatureCanvasModal={isVisibleSignatureCanvasModal}
                handleCancelSignatureCanvas={handleCancelSignatureCanvas}
                handleCloseCreateSignatureModal={handleCloseCreateSignatureModal}
                handleCreatedSuccess={handleCreatedSuccess}
            />
        </ModalWrapper>
    )
}

CreateSignatureModal.propTypes = {}

export default inject(
    'signedDocumentStore',
    'loadingAnimationStore',
    'fileStore',
    'authenticationStore',
)(observer(CreateSignatureModal))
