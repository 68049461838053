import React, { memo, useCallback, useEffect, useState } from 'react'
import { Col, message, Popover, Row, Space } from 'antd'
import { AppstoreFilled } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import {
  BAO_CAO_THONG_MINH,
  CLOUD_ACTIVITY,
  CLOUD_DOCUMENT,
  CLOUD_DU_LIEU,
  CLOUD_EMAIL,
  CLOUD_FILE,
  CLOUD_TALK,
  DANH_GIA,
  DAO_TAO_TRUC_TUYEN,
  FAQ,
  HR,
  KTT,
  LIEN_THONG,
  NHAN_SU,
  TAI_CHINH_KE_TOAN,
  THU_DIEN_TU,
  VAN_PHONG_DIEN_TU,
  VAN_PHONG_DIEN_TU_OLD,
} from '../../constants'
import { findLast } from 'lodash-es'

const PopupAppGrid = props => {
  const { history, authenticationStore, commandStore, moduleStore } = props

  const { commandList } = commandStore
  const { moduleList } = moduleStore
  const [vpdtApp, setVpdtApp] = useState([])

  useEffect(() => {
    let moduleVpdt = findLast(moduleList, item => item.code === 'vpdt_app')
    setVpdtApp((moduleVpdt?.status && moduleVpdt?.sub_modules) ?? [])
  }, [moduleList])

  const renderWorkingPlace = () => {
    const menuCodes = [
      VAN_PHONG_DIEN_TU,
      CLOUD_FILE,
      CLOUD_TALK,
      VAN_PHONG_DIEN_TU_OLD,
      HR,
      FAQ,
      DANH_GIA,
    ]
    const menuList = [
      ...commandList,
      { code: VAN_PHONG_DIEN_TU_OLD, url: 'http://192.168.73.95:8080' },
    ].filter(item => menuCodes.includes(item.code) && isEnableApp(item.code))
    return menuList.map(item => renderCommandMenu({ item, col: 6 }))
  }

  const renderMenuApp = () => {
    const menuCodes = [
      CLOUD_DOCUMENT,
      DAO_TAO_TRUC_TUYEN,
      BAO_CAO_THONG_MINH,
      NHAN_SU,
      CLOUD_EMAIL,
      KTT,
      FAQ,
      DANH_GIA,
    ]
    const menuList = commandList.filter(item => menuCodes.includes(item.code))
    //&& isEnableApp(item.code)
    return menuList.map(item => renderCommandMenu({ item, col: 6 }))
  }

  const isEnableApp = code =>
    findLast(vpdtApp, module => module.code === code)?.status === true

  const renderCommandMenu = ({ item, col }) => {
    let title = null
    let iconPath = null
    switch (item.code) {
      case NHAN_SU:
        title = 'CeHR'
        iconPath = 'cehr.svg'
        break
      case VAN_PHONG_DIEN_TU:
        title = 'E-Office'
        iconPath = 'eoffice.svg'
        break
      case VAN_PHONG_DIEN_TU_OLD:
        title = 'E-Office Old'
        iconPath = 'E-Office-Old.png'
        break
      case DAO_TAO_TRUC_TUYEN:
        title = 'E-Learning'
        iconPath = 'elearning.svg'
        break
      case TAI_CHINH_KE_TOAN:
        title = 'Fast'
        iconPath = 'fast.svg'
        break
      case THU_DIEN_TU:
        title = 'Email'
        iconPath = 'email.svg'
        break
      case BAO_CAO_THONG_MINH:
        title = 'Mis-BI'
        iconPath = 'misbi.svg'
        break
      case CLOUD_DU_LIEU:
        title = 'VIMC-Cloud'
        iconPath = 'cloud.svg'
        break
      case CLOUD_FILE:
        title = 'VIMC-FILE'
        iconPath = 'cloud.svg'
        break
      case CLOUD_TALK:
        title = 'VIMC-TALK'
        iconPath = 'website.svg'
        break
      case CLOUD_EMAIL:
        title = 'E-Mail'
        iconPath = 'email.svg'
        break
      case CLOUD_DOCUMENT:
        title = 'VIMC Tài liệu'
        iconPath = 'vimc_document.svg'
        break
      case CLOUD_ACTIVITY:
        title = 'VIMC-ACTIVITY'
        iconPath = 'cloud.svg'
        break
      case LIEN_THONG:
        title = 'VIMC-Connect'
        iconPath = 'website.svg'
        break
      case 'CMD4351234481':
        title = 'VIMC - Office'
        iconPath = 'website.svg'
        break
      case 'HR':
        title = 'VIMC - HR'
        iconPath = 'hr.png'
        break
      case KTT:
        title = 'Kho tri thức'
        iconPath = 'ktt.svg'
        break
      case FAQ:
        title = 'Hỏi đáp chính sách'
        iconPath = 'faq.svg'
        break
      case DANH_GIA:
        title = 'Đánh giá Ban CMNV'
        iconPath = 'rating.svg'
        break
    }

    return title != null ? cardItem(item, col, title, iconPath) : null
  }

  const cardItem = (item, col, title, iconPath) => {
    return (
      <Col
        key={item.code}
        span={col}
        onClick={() => clickWidget(item)}
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          cursor: 'pointer',
        }}>
        <Space direction={'vertical'} align={'center'}>
          <div style={{ width: 54, height: 54 }}>
            <img
              style={{ width: '100%' }}
              src={`${process.env.PUBLIC_URL}/assets/icons/portal/${iconPath}`}
              alt={title}
            />
          </div>
          <h6 style={{ fontSize: 11, fontWeight: 600, textAlign: 'center' }}>
            {title}
          </h6>
        </Space>
      </Col>
    )
  }

  const clickWidget = item => {
    switch (item.code) {
      case THU_DIEN_TU:
      case CLOUD_DU_LIEU:
      case CLOUD_FILE:
      case CLOUD_TALK:
      case CLOUD_EMAIL:
      case CLOUD_ACTIVITY:
        appActionHandler(item)
        break
      case VAN_PHONG_DIEN_TU:
        if (authenticationStore.currentUser) {
          history.push('/dashboard')
        }
        break
      default:
        window.open(item.url)
        break
    }
  }

  const appActionHandler = useCallback(
    app => {
      let route
      switch (app.code) {
        case THU_DIEN_TU:
          route = '/eMail'
          break
        case CLOUD_DU_LIEU:
          route = '/cloud'
          break
        case CLOUD_FILE:
          route = '/cloud?code=' + CLOUD_FILE
          break
        case CLOUD_TALK:
          route = '/cloud?code=' + CLOUD_TALK
          break
        case CLOUD_EMAIL:
          route = 'https://mail.vimc.co/'
          break
        case CLOUD_ACTIVITY:
          route = '/cloud?code=' + CLOUD_ACTIVITY
          break
        case LIEN_THONG:
          route = 'vimc-connect'
          break
        case KTT:
          route = 'https://ktt.vimc.co/'
          break
      }
      if (
        app.code === CLOUD_DU_LIEU ||
        app.code === CLOUD_FILE ||
        app.code === CLOUD_TALK ||
        app.code === CLOUD_EMAIL ||
        app.code === CLOUD_ACTIVITY
      ) {
        const win = window.open(route, '_blank')
        win.focus()
      } else {
        app.account_name
          ? history.push({
              pathname: route,
              state: {
                username: app.account_name,
                password: app.password,
                commandUrl: app.url,
              },
            })
          : message.error(
              'Chưa đăng ký tài khoản, vui lòng liên hệ trung tâm CNTT'
            )
      }
    },
    [history]
  )

  return (
    <Popover
      placement="bottomRight"
      trigger="click"
      content={
        <div style={{ width: 388 }}>
          <Row
            gutter={[8, 24]}
            style={{
              backgroundColor: 'white',
              borderRadius: '6px',
              padding: '18px 0',
              marginBottom: 0,
            }}>
            {renderWorkingPlace()}
            {renderMenuApp()}
          </Row>
        </div>
      }>
      <AppstoreFilled style={{ color: '#fff', fontSize: 18, marginLeft: 14 }} />
    </Popover>
  )
}

PopupAppGrid.propTypes = {}

export default memo(
  withRouter(
    inject(
      'authenticationStore',
      'accountStore',
      'userStore',
      'companyStore',
      'commonStore',
      'commandStore',
      'moduleStore',
      'aclStore'
    )(observer(PopupAppGrid))
  )
)
