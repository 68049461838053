import React, { memo } from 'react'
import { inject, observer } from 'mobx-react'
import DashboardLayout from '../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'

const BiPage = props => {
  return (
    <DashboardLayout>
      <Helmet>
        <title>Màn hình báo cáo</title>
      </Helmet>
      <PageTitle
        location={props.location}
        title={'BI'}
        hiddenGoBack></PageTitle>
      <ContentBlockWrapper styles={{ height: '100%' }}>
        <iframe
          title="TONG QUAN VIMC"
          width="100%"
          height="800"
          src="https://app.powerbi.com/view?r=eyJrIjoiZDQwODJlM2YtZjQ5My00MGE3LWExZWQtMDA5ZGI4OWVjZWMzIiwidCI6Ijc3M2UyNTU5LTlkMGEtNDBmYi04NmJiLTM1ZjY1Zjc5ODJkNSIsImMiOjEwfQ%3D%3D"
          frameBorder={0}
          allowFullScreen={true}></iframe>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

BiPage.propTypes = {}

export default memo(inject()(observer(BiPage)))
