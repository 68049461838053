import React from 'react'
import { useRef } from 'react'
import { Button, Col, Form, Input, message, Modal, Row, Select } from 'antd'
import RichEditor from '../../components/RichEditor/RichEditor'
import { FormActionFooter } from '../ConnectedDocumentIncomingDocCreatePage/ConnectedDocumentIncomingDocCreatePageStyled'
import { CloseOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { systemName } from '../../config'
import { handleFilterSelect } from '../../utils/filterSelect'
import validator from '../../validator'

const { Option } = Select
const RiskUpdatePopup = ({
  handleCancel,
  isVisible,
  riskStore,
  getRiskDetail,
  departmentStore,
  type,
  isCreate = false,
}) => {
  const {
    riskUnits,
    riskDetail,
    updateRisk,
    createRisk,
    riskUnitsV2,
  } = riskStore
  const { riskId } = useParams()
  const { departmentList } = departmentStore
  const [form] = Form.useForm()
  const CONTENT_REF = useRef()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [riskUnitV3, setRiskUnitV3] = useState([])
  const [departV2, setDepartV2] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [responseUnit, responseDepart] = await Promise.all([
          riskStore.getRiskUnitsV3(),
          departmentStore.getAllDepartmentListV2(),
        ])
        setRiskUnitV3(responseUnit.data)
        setDepartV2(responseDepart.data)
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      }
    }

    fetchData()
  }, [riskStore, departmentStore])

  useEffect(() => {
    form.setFieldsValue({
      title: riskDetail.title,
      risk_unit_code: riskDetail.risk_unit_code,
    })
  }, [riskDetail])

  // useEffect(() => {
  //   if (
  //     !departmentList?.map(el => el.code)?.includes(riskDetail?.risk_unit_code)
  //   ) {
  //     form.setFieldsValue({ risk_unit_code: null })
  //   }
  // }, [])

  useEffect(() => {
    ;(async () => {
      try {
        await riskStore.getRiskUnits()
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      }
    })()
  }, [])

  const handleUpdateRisk = async value => {
    setIsLoading(true)
    const { title, risk_unit_code } = value
    const submitData = {
      ...value,
      content: CONTENT_REF.current.editor.getData(),
    }
    try {
      if (isCreate) {
        const submitData = {
          risk_unit_code,
          title,
          type,
          tiers: [
            {
              name: 'Tuyến phòng vệ thứ nhất',
              level: 1,
              approvals: [
                {
                  name:
                    type === 'NDDPV'
                      ? 'Người đại diện phần vốn thực hiện phiếu'
                      : 'Chuyên viên thực hiện',
                  level: 1,
                  peoples: [],
                },
                {
                  name: 'Lãnh đạo đơn vị',
                  level: 2,
                  peoples: type === 'NDDPV' ? [] : [],
                },
              ],
              actions: [],
            },
            {
              name: 'Tuyến phòng vệ thứ hai',
              level: 2,
              approvals: [
                {
                  name: 'Đơn vị thực hiện xử lý rủi ro',
                  level: 1,
                  peoples: [],
                  // peoples: selectUserList.VIMCLeader?.map(user => ({
                  //   username: user.id,
                  //   department_code: user.department_code,
                  //   status: 'CREATE',
                  // })),
                },
                {
                  name: `Lãnh đạo ${systemName}`,
                  level: 2,
                  peoples: [],
                },
              ],
              actions: [],
            },
            {
              name: 'Tuyến phòng vệ thứ ba',
              level: 3,
              approvals: [
                {
                  name: 'Đơn vị thực hiện xử lý rủi ro',
                  level: 1,
                  peoples: [],
                },
                {
                  name: `Ban KTNB ${systemName}`,
                  level: 2,
                  peoples: [],
                },
                {
                  name: 'Hội đồng quản trị',
                  level: 3,
                  peoples: [],
                },
              ],
              actions: [],
            },
            {
              name: 'Kết quả',
              level: 4,
              approvals: [],
              actions: [],
            },
          ],
        }
        const res = await createRisk(submitData)
        history.push({
          pathname: `risk/view/${res.data.code}?type=${res.data.type}`,
        })
        message.success(`Tạo phiếu rủi ro thành công`)
      } else {
        handleCancel()
        await updateRisk(riskId, submitData)
        await getRiskDetail()
        message.success('Cập nhật thành công!')
      }
    } catch (err) {
      console.log(err.vi || 'Đã có lỗi xảy ra!')
      message.error(err.vi || 'Cập nhật thất bại!')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      width={800}
      style={{ top: 40, zIndex: 500 }}
      title={
        isCreate
          ? 'Tạo mới phiếu kiểm soát rủi ro'
          : 'Cập nhật phiếu kiểm soát rủi ro'
      }
      visible={isVisible}
      maskClosable={false}
      onCancel={() => {
        handleCancel()
      }}
      footer={null}>
      <Form form={form} layout={'vertical'} onFinish={handleUpdateRisk}>
        <Row type={'flex'} gutter={15}>
          <Col span={12}>
            <Form.Item
              name={'title'}
              label={'Tiêu đề:'}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập tiêu đề!',
                },
                {
                  max: 255,
                  message: 'Tiêu đề đã đủ 256 ký tự, không thể nhập thêm!',
                },
                { validator: validator.validateInputString },
              ]}>
              <Input placeholder={'Tiêu đề phiếu kiểm soát rủi ro'} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={'risk_unit_code'}
              label={'Đơn vị:'}
              rules={[{ required: true, message: 'Vui lòng chọn đơn vị!' }]}>
              <Select
                placeholder={'Đơn vị'}
                showSearch
                allowClear
                filterOption={handleFilterSelect}>
                {type !== 'CQVP'
                  ? riskUnitsV2?.map(unit => (
                      <Option
                        key={unit.code}
                        name={'risk_unit_code'}
                        value={unit.code}>
                        {unit.name}
                      </Option>
                    ))
                  : Array.isArray(departmentList) &&
                    departmentList?.map(department => (
                      <Option
                        key={department.code}
                        name={'department_code'}
                        value={department.code}>
                        {department.name}
                      </Option>
                    ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={'Nội dung:'}
          name={'content'}
          style={isCreate ? { display: 'none' } : {}}>
          <RichEditor
            EDITOR_REF={CONTENT_REF}
            placeholder={'Nhập nội dung...'}
            editorContent={riskDetail.content && riskDetail.content}
          />
        </Form.Item>
        <FormActionFooter style={{ marginBottom: 0 }}>
          <Button
            icon={<CloseOutlined />}
            danger
            onClick={() => {
              handleCancel()
            }}>
            Đóng
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            type={'primary'}
            htmlType={'submit'}
            loading={isLoading}>
            {isCreate ? 'Tạo mới' : 'Cập nhật'}
          </Button>
        </FormActionFooter>
      </Form>
    </Modal>
  )
}

export default inject(
  'selectPeopleStore',
  'authenticationStore',
  'loadingAnimationStore',
  'riskStore',
  'departmentStore'
)(observer(RiskUpdatePopup))
