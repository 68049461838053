import React, { memo, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
// Components
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import EmptyContent from '../../../components/EmptyContent'
import PageTitle from '../../../components/PageTitle'
import PopupRenameFile from '../../../components/PopupRenameFile'
import PopupSign from '../../../components/PopupSign/PopupSign'
// Layout
import { Container } from '../../../layouts/Container/Container'
import DashboardLayout from '../../../layouts/DashboardLayout'
// Styled component
import {
  FormActionFooter,
  SelectList,
  SelectTitle,
  UploadFileListItem,
  UploadFileListWrapper,
} from './InternalDocumentIncomingDocCreatePageStyled'
// Ant design
import {
  BankOutlined,
  DeleteOutlined,
  FileTextOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
  Upload,
  message,
} from 'antd'
// Other
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { Document } from 'react-pdf'
import { blue, yellowPrimary } from '../../../color'
import AccessControlAction from '../../../components/AccessControlAction'
import { AvatarHasName } from '../../../components/Common/Avatar'
import SelectGroupPopup from '../../../components/SelectGroupPopup/SelectGroupPopup'
import SelectPeoplePopup from '../../../components/SelectPeoplePopup/SelectPeoplePopup'
import {
  ACL_ACTION_TYPE,
  ASSIGNEE_TYPE,
  DATE_FORMAT_LIST,
  DIGITAL_SIGN_PROVIDER,
  DIGITAL_TYPE_SIGN_SYSTEM,
  DOCUMENT_TYPE,
} from '../../../constants'
import utils from '../../../utils'
import validator from '../../../validator'
import AuthoritiesActionModal from '../../AuthoritiesPage/AuthoritiesActionModal'
import { systemName } from '../../../config'

const { RangePicker } = DatePicker
const { Option } = Select
const { TextArea } = Input
const { Title } = Typography

const InternalDocumentIncomingDocCreatePage = props => {
  const {
    history,
    internalDocumentStore,
    fileStore,
    bookStore,
    authorityStore,
    companyStore,
    loadingAnimationStore,
    signedDocumentStore,
    connectedDocumentStore,
    selectPeopleStore,
  } = props

  const { responseSign } = signedDocumentStore
  const {
    selectUserData,
    selectDepartmentData,
    selectGroupData,
    isVisibleSelectGroupPopup,
    setIsVisibleSelectGroupPopup,
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleOpenSelectGroupPopup,
    handleCancelSelectGroup,
    originDepartmentWithUserList,
  } = selectPeopleStore

  const { authorityIssuedsList } = authorityStore

  const { bookGroupList, bookListOfGroup } = bookStore
  // Dữ liệu văn bản nhận từ trục
  const { dataReceiveDocument } = connectedDocumentStore
  const [form] = Form.useForm()
  const [formRenameFile] = Form.useForm()

  const [selectedBookGroupId, setSelectedBookGroupId] = useState(null)
  const [authorityIssuedId, setAuthorityIssuedId] = useState(undefined)
  // For Rename file
  const [fileExt, setFileExt] = useState(undefined)
  const [fileTargetRename, setFileTargetRename] = useState(null)
  const [isRenameFileFromTruc, setIsRenameFileFromTruc] = useState(false)
  const [isModalVisibleRenameFile, setIsModalVisibleRenameFile] = useState(
    false
  )
  // List file nhận từ thiết bị
  const [fileList, setFileList] = useState([])
  // List file nhận từ trục
  const [fileFromTruc, setFileFromTruc] = useState([])
  // vẽ ngầm canvas ký số
  const [viewPortPdf, setViewPortPdf] = useState()
  const [fileRender, setFileRender] = useState()
  const [dataSignSavis, setDataSignSavis] = useState()
  // Ký số popup
  const [isVisiblePopupSign, setIsVisiblePopupSign] = useState(false)
  const [handlerList, setHandlerList] = useState([])
  const [combinerList, setCombinerList] = useState([])
  const [followerList, setFollowerList] = useState([])

  const [
    isVisibleAuthoritiesActionModal,
    setIsVisbleAuthoritiesActionModal,
  ] = useState(false)

  useEffect(() => {
    setFollowerList([
      ...selectUserData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectGroupData[ASSIGNEE_TYPE.FOLLOWER],
    ])
    setHandlerList([
      ...selectUserData[ASSIGNEE_TYPE.HANDLER],
      ...selectDepartmentData[ASSIGNEE_TYPE.HANDLER],
      ...selectGroupData[ASSIGNEE_TYPE.HANDLER],
    ])
    setCombinerList([
      ...selectUserData[ASSIGNEE_TYPE.COMBINER],
      ...selectDepartmentData[ASSIGNEE_TYPE.COMBINER],
      ...selectGroupData[ASSIGNEE_TYPE.COMBINER],
    ])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  const handleSelectBookGroupId = bookGroupId => {
    if (!bookGroupId) setSelectedBookGroupId(null)
    form.setFieldsValue({
      book_id: undefined,
    })
    form.validateFields(['book_id'])
    setSelectedBookGroupId(bookGroupId)
    loadingAnimationStore.showSpinner(true)
    bookStore
      .getBookByBookGroupId(bookGroupId)
      .finally(() => loadingAnimationStore.showSpinner(false))
  }

  const handleSelectBookId = async bookId => {
    if (!bookId) return
    try {
      const response = await internalDocumentStore.suggestIncomingNumber(bookId)
      form.setFieldsValue({
        incoming_number: response.data?.incoming_number,
      })
    } catch (error) {
      console.log(error)
      message.error(error?.vi || 'Đã có lỗi xảy ra!')
    }
  }

  const handleChangeFile = info => {
    if (info.fileList.length === 0) {
      form.setFieldsValue({ file_upload: undefined })
      setFileList([])
      return
    }
    const filteredFileList = info.fileList.filter(
      (elem, index, fileList) =>
        fileList.findIndex(file => file.name === elem.name) === index
    )
    setFileList(filteredFileList)
  }

  const handleRemoveFileFromUploadList = useCallback(
    fileUID => {
      const newFileList = fileList.filter(file => file.uid !== fileUID)
      setFileList(newFileList)
      if (newFileList.length === 0) {
        form.setFieldsValue({
          attachments: [],
        })
      }
    },
    [fileList]
  )

  const handleRemoveFileFromTruc = file_id => {
    setFileFromTruc(
      toJS([...fileFromTruc]).filter(file => file.fileId !== file_id)
    )
  }

  // vẽ ngầm canvas lấy kích thước file pdf (Start ký savis)
  const renderPage = async pdf => {
    const page = await pdf.getPage(1)
    const viewport = page.getViewport({ scale: 1 })
    setViewPortPdf({ height: viewport.height, width: viewport.width })
  }

  const renderDocument = () => {
    return (
      <Document
        noData={null}
        style={{ display: 'none' }}
        file={fileRender?.originFileObj}
        onLoadSuccess={pdf => onDocumentLoadSuccess(pdf)}
      />
    )
  }

  const onDocumentLoadSuccess = pdf => {
    renderPage(pdf, 1)
  }

  const handleBeforeSign = (file, index) => {
    setFileRender(file)
    setDataSignSavis({
      file: file,
      index: index,
    })
  }

  useEffect(() => {
    if (!responseSign) return
    const { fileSignId, fileSignName } = responseSign.data.listSignFile[0]
    const newFileList = [...fileList]
    newFileList[dataSignSavis.index] = {
      name: fileSignName,
      id: fileSignId,
      uid: dataSignSavis.file.uid,
      isSigned: true,
      type: 'application/pdf',
    }
    setFileList(newFileList)
  }, [responseSign])

  const handleSignSavis = (file, index) => {
    loadingAnimationStore.showSpinner(true)
    const formData = new FormData()
    formData.append('file', file.originFileObj)
    fileStore
      .uploadFile(formData)
      .then(res => {
        const dataSubmit = {
          image: '',
          reason: 'Signed',
          location: 'VietNam',
          provider: DIGITAL_SIGN_PROVIDER,
          contactInfo: '',
          type: DIGITAL_TYPE_SIGN_SYSTEM,
          isVisible: 1,
          page: 1,
          llx: 291,
          lly: 744,
          urx: 425,
          ury: 878,
          listSignFile: [
            {
              fileId: res.data.file_id,
            },
          ],
        }
        signedDocumentStore
          .signDocumentSavis(dataSubmit)
          .then(res => {
            const {
              fileSignId,
              fileOriginName,
              fileSignName,
            } = res.data.listSignFile[0]
            const newFileList = [...fileList]
            newFileList[index] = {
              name: fileSignName,
              id: fileSignId,
              uid: file.uid,
              isSigned: true,
              type: 'application/pdf',
            }
            setFileList(newFileList)
            loadingAnimationStore.showSpinner(false)
            message.success(`Văn bản "${fileOriginName}" đã được kí`).then()
          })
          .catch(err => {
            loadingAnimationStore.showSpinner(false)
            return message.error(err?.vi || 'Ký số thất bại!')
          })
      })
      .catch(() => loadingAnimationStore.showSpinner(false))
  }
  // End ký savis

  const handleCreateInternalDocumentIncoming = async submitData => {
    await internalDocumentStore.createInternalDocumentIncoming(submitData)
    message.success(`Tạo văn bản thành công`)
  }
  const [start_date, setStartDate] = useState(null)
  const [end_date, setEndDate] = useState(null)

  const handleDateChange = async value => {
    setStartDate(value ? moment(value[0]) : null)
    setEndDate(value ? moment(value[1]) : null)
  }

  const onFinish = async values => {
    const fileSignedList = fileList
      .filter(file => file.isSigned)
      .map(file => file.id)
    const {
      authority_issued_id,
      book_id,
      date_issued,
      document_number,
      outgoing_date,
      signer,
      title,
      book_group_id,
      urgency_level,
      incoming_number,
    } = values
    loadingAnimationStore.showSpinner(true)
    const assigneesList = [
      ...followerList,
      ...handlerList,
      ...combinerList,
    ].map(el => ({
      assignee_code: el.id,
      assignee_type: el.assignee_type,
      permission: el.permission,
    }))
    let fileBoldFormTrucList = []
    await Promise.all(
      fileFromTruc.map(file => fileStore.downloadAttachmentTruc(file.fileId))
    )
      .then(response => {
        fileBoldFormTrucList = response
      })
      .catch(err => {
        console.log(err)
        message.warning(
          'Download file từ trục liên thông có lỗi, bạn hãy thử nhận lại văn bản!'
        )
      })
    const uploadedFileFromTruc = await Promise.all(
      fileBoldFormTrucList.map((fileBold, index) => {
        const formData = new FormData()
        formData.append('file', fileBold.data, fileFromTruc[index].name)
        return fileStore.uploadFile(formData)
      })
    )
    const idFileUploadFromTruc = uploadedFileFromTruc.map(
      file => file.data.file_id
    )
    const batchUploadList = []
    fileList.forEach(file => {
      if (file.isSigned) return
      const formData = new FormData()
      formData.append('file', file.originFileObj, file.name)
      batchUploadList.push(fileStore.uploadFile(formData))
    })

    try {
      let response = await Promise.all(batchUploadList)
      const submitValues = {
        authority_issued_id: authority_issued_id,
        assign_user: {
          assignees: assigneesList,
          start_date: start_date,
          end_date: end_date,
        },
        attachments: response
          .map(response => response.data.file_id)
          .concat(fileSignedList)
          .concat(idFileUploadFromTruc),
        book_group_id: book_group_id,
        book_id: book_id,
        date_issued: date_issued ? moment(date_issued).toISOString() : null,
        document_number: document_number,
        document_type: null,
        incoming_number: incoming_number,
        outgoing_date: outgoing_date
          ? moment(outgoing_date).toISOString()
          : null,
        sign_date: null,
        signer: signer,
        title: title,
        urgency_level: urgency_level,
      }
      await handleCreateInternalDocumentIncoming(submitValues)
      handleCancelCreate()
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleCancelCreate = () => {
    form.resetFields()
    history.push('/internal-document/incoming-document')
  }

  // For đổi tên file nhận từ thiết bị
  const renameFile = (originalFile, newName) => {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    })
  }

  const submitRenameFile = async value => {
    const index = fileTargetRename?.index
    // đổi tên file nhận từ trục
    if (isRenameFileFromTruc) {
      const fileFromTrucAfterRename = [...fileFromTruc]
      fileFromTrucAfterRename[index] = {
        fileId: fileTargetRename.fileId,
        name: value.rename + fileExt,
      }
      setFileFromTruc(fileFromTrucAfterRename)
      setIsModalVisibleRenameFile(false)
      message.success('Đổi tên file thành công')
      return
    }
    // đổi tên file nhận từ thiết bị (file chưa ký)
    if (fileTargetRename.originFileObj) {
      const newFileList = [...fileList]
      newFileList[index].name = value.rename + fileExt
      newFileList[index].originFileObj = renameFile(
        newFileList[index].originFileObj,
        newFileList[index].name
      )
      setFileList(newFileList)
      setIsModalVisibleRenameFile(false)
      message.success('Đổi tên file thành công')
    }
    // đổi tên file nhận từ thiết bị (file đã ký)
    if (!fileTargetRename.originFileObj) {
      await fileStore.handleRenameFile(
        fileTargetRename.id,
        value.rename + fileExt
      )
      setIsModalVisibleRenameFile(false)
      const newFileList = [...fileList]
      newFileList[index].name = value.rename + fileExt
      setFileList(newFileList)
      message.success('Đổi tên file thành công')
    }
  }

  useEffect(() => {
    if (
      !dataReceiveDocument ||
      !dataReceiveDocument.attachments ||
      !dataReceiveDocument.attachments.length
    )
      return
    setFileFromTruc([...toJS(dataReceiveDocument.attachments)])
  }, [])

  useEffect(() => {
    ; (async () => {
      loadingAnimationStore.showSpinner(true)
      try {
        authorityStore.setAuthorityIssuedsQueryParams({
          type: DOCUMENT_TYPE.INCOMING,
          key: null,
        })
        await authorityStore.getAuthorityIssueds()
        await companyStore.getCompanyList()
        const responseGetBookGroup = await bookStore.getBookGroup('DEN')
        const responseBookByBookGroupId = await bookStore.getBookByBookGroupId(
          responseGetBookGroup.data[0].id
        )
        const responseSuggestIncomingNumber = await internalDocumentStore.suggestIncomingNumber(
          responseBookByBookGroupId.data[0]?.id
        )
        form.setFieldsValue({
          book_group_id:
            responseGetBookGroup.data?.length > 0
              ? responseGetBookGroup.data[0].id
              : null,
          book_id:
            responseBookByBookGroupId.data?.length > 0
              ? responseBookByBookGroupId.data[0]?.id
              : null,
          incoming_number: responseSuggestIncomingNumber.data?.incoming_number,
        })
      } catch (error) {
        console.log(error)
        message.error(error?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
    return () => {
      form.resetFields()
      bookStore.clearStore()
      signedDocumentStore.clearFilePDFSign()
      selectPeopleStore.clearSelectPeopleStore()
    }
  }, [])

  // sign popup
  const handleClosePopupSign = () => {
    setIsVisiblePopupSign(false)
    signedDocumentStore.clearFilePDFSign()
  }

  const handleOpenPopupSign = (file, index) => {
    setDataSignSavis({
      file: file.originFileObj,
      index: index,
    })
    const fileBlob = new Blob([file.originFileObj], { type: 'application/pdf' })
    signedDocumentStore.setFileBlob(fileBlob)
    signedDocumentStore.setOriginFileObj(file.originFileObj)
    setIsVisiblePopupSign(true)
  }

  const [fieldsRenameFile, setFieldsRenameFile] = useState([
    {
      name: ['rename'],
      value: '',
    },
  ])

  const handleOpenPopupRenameFile = (file, index) => {
    setFieldsRenameFile([
      {
        name: ['rename'],
        value: utils.removeExtensionFile(file.name),
      },
    ])
    setFileExt('.' + utils.getExtensionFile(file.name))
    setFileTargetRename({
      ...file,
      index: index,
    })
    setIsModalVisibleRenameFile(true)
  }
  const [
    keywordFilterDocumentNumber,
    setKeywordFilterDocumentNumber,
  ] = useState(null)

  const onChangeFilterDocumentNumber = keyword => {
    setKeywordFilterDocumentNumber(keyword === '' ? null : keyword)
  }

  const validateDocumentNumber = async (rule, value) => {
    if (!value || value.trim() === '') {
      return Promise.reject('Cần nhập ký tự, vui lòng không bỏ trống')
    } else if (value < 0) {
      return Promise.reject('Cần nhập số lớn hơn 0!')
    } else {
      if (value === undefined) return
      try {
        let bookId = form.getFieldValue('book_id')
        if (!bookId) {
          return Promise.reject('Chưa chọn sổ văn bản!')
        }
        const response = await internalDocumentStore.getWarningDocumentNumber(
          null,
          bookId,
          value,
          'INCOMING'
        )
        if (response.data.status === 'WARNING') {
          return Promise.reject(response.data.message)
        } else {
          return Promise.resolve()
        }
      } catch (error) {
        console.log(error)
        message.error(error?.vi || 'Đã có lỗi xảy ra!')
      }
    }
  }

  const filterSelectOption = (input, option) => {
    return (
      utils
        .nonAccentVietnamese(option.name)
        .indexOf(utils.nonAccentVietnamese(input)) >= 0
    )
  }

  const handleToggleAuthoritiesActionModal = bool => event => {
    setIsVisbleAuthoritiesActionModal(bool)
  }

  return (
    <>
      <DashboardLayout>
        <Helmet>
          <title>
            Tạo văn bản đến | {systemName} Quản lý VB {systemName}
          </title>
        </Helmet>
        <PageTitle location={props.location} title={'Tạo văn bản đến'} />
        <ContentBlockWrapper>
          <Container maxWidth={1000}>
            <Form
              form={form}
              scrollToFirstError={true}
              name={'create-incoming-document'}
              layout={'vertical'}
              style={{ paddingTop: '2rem' }}
              onFinish={onFinish}
              initialValues={{
                outgoing_date: moment(),
                signer: dataReceiveDocument && dataReceiveDocument.signer,
                title: dataReceiveDocument && dataReceiveDocument.subject,
                document_number:
                  dataReceiveDocument && dataReceiveDocument.code,
                authority_issued_id: authorityIssuedsList.find(
                  ({ name }) =>
                    name?.trim()?.toUpperCase() ===
                    dataReceiveDocument?.userFrom?.trim()?.toUpperCase()
                )?.id,
                date_issued:
                  dataReceiveDocument &&
                  dataReceiveDocument.promulgationDate &&
                  moment(dataReceiveDocument.promulgationDate),
              }}>
              <Row type={'flex'} gutter={30}>
                <Col xs={24} md={12}>
                  <Form.Item
                    label={'Nhóm sổ văn bản'}
                    name={'book_group_id'}
                    rules={[
                      {
                        required: true,
                        message: ' Vui lòng chọn nhóm sổ văn bản!',
                      },
                    ]}>
                    <Select
                      onChange={handleSelectBookGroupId}
                      showSearch
                      notFoundContent={<EmptyContent />}
                      filterOption={true}
                      optionFilterProp={'name'}
                      placeholder={'Chọn nhóm sổ văn bản'}>
                      {bookGroupList.map(book => (
                        <Option key={book.id} value={book.id} name={book.name}>
                          {book.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label={'Sổ văn bản'}
                    name={'book_id'}
                    rules={[
                      { required: true, message: ' Vui lòng chọn sổ văn bản!' },
                    ]}>
                    <Select
                      showSearch
                      onChange={handleSelectBookId}
                      notFoundContent={<EmptyContent />}
                      filterOption={true}
                      optionFilterProp={'name'}
                      placeholder={'Chọn sổ văn bản'}>
                      {bookListOfGroup.map(book => (
                        <Option key={book.id} value={book.id} name={book.name}>
                          {book.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row type={'flex'} gutter={30}>
                <Col xs={24} md={12}>
                  <Form.Item
                    label={'Số hiệu'}
                    name={'document_number'}
                    rules={[
                      { required: true, message: ' ' },
                      { validator: validateDocumentNumber },
                    ]}>
                    <Input
                      value={keywordFilterDocumentNumber}
                      onChange={e =>
                        onChangeFilterDocumentNumber(e.target.value)
                      }
                      // style={{ textTransform: 'uppercase' }}
                      maxLength={50}
                      placeholder={'Nhập số hiệu văn bản'}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label={'Số đến'}
                    name={'incoming_number'}
                    rules={[
                      { required: true, message: ' Vui lòng nhập số đến!' },
                      // { validator: validator.validateInputString },
                    ]}>
                    <Input maxLength={50} placeholder={'Nhập số đến văn bản'} />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label={'Trích yếu'}
                name={'title'}
                rules={[
                  {
                    required: true,
                    message: ' Vui lòng nhập trích yếu văn bản!',
                  },
                  { validator: validator.validateInputString },
                ]}>
                <TextArea
                  autoSize={{ minRows: 4 }}
                  placeholder={'Nhập trích yếu văn bản'}
                />
              </Form.Item>
              <Row type={'flex'} gutter={30}>
                <Col xs={24} md={12}>
                  <Form.Item
                    rules={[
                      { required: true, message: 'Vui lòng chọn ngày đến!' },
                    ]}
                    label={'Ngày đến'}
                    name={'outgoing_date'}>
                    <DatePicker
                      style={{ width: '100%' }}
                      format={DATE_FORMAT_LIST}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label={'Ngày văn bản'} name={'date_issued'}>
                    <DatePicker
                      style={{ width: '100%' }}
                      format={DATE_FORMAT_LIST}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row type={'flex'} gutter={30}>
                <Col xs={24} md={12}>
                  <Form.Item label={'Người ký'} name={'signer'}>
                    <Input maxLength={500} placeholder={'Nhập tên người ký'} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label={'Độ khẩn'} name={'urgency_level'}>
                    <Select
                      allowClear
                      notFoundContent={<EmptyContent />}
                      placeholder={'Chọn độ khẩn'}>
                      <Option value={'001'}>Khẩn</Option>
                      <Option value={'002'}>Hỏa tốc</Option>
                      <Option value={'003'}>Hỏa tốc hẹn giờ</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name={'attachments'}
                label={
                  fileFromTruc.length !== 0 ? (
                    <>
                      <span
                        style={{
                          color: '#ff4d4f',
                          fontFamily: 'SimSun, sans-serif',
                          marginRight: '2px',
                        }}>
                        *{' '}
                      </span>
                      Tài liệu đính kèm
                    </>
                  ) : (
                    'Tài liệu đính kèm'
                  )
                }
                rules={
                  fileFromTruc.length !== 0
                    ? null
                    : [
                      {
                        required: true,
                        message: ' Vui lòng chọn tài liệu đính kèm!',
                      },
                    ]
                }>
                <Upload
                  valuePropName={'fileList'}
                  fileList={fileList}
                  multiple={true}
                  beforeUpload={() => false}
                  onChange={handleChangeFile}
                  showUploadList={false}>
                  <Button icon={<UploadOutlined />}>
                    Chọn tài liệu đính kèm
                  </Button>
                </Upload>
              </Form.Item>
              {
                <UploadFileListWrapper>
                  {fileList.map((file, index) => (
                    <UploadFileListItem
                      key={file.uid || file.id}
                      isSigned={file.isSigned}
                      isNotPDF={file.type !== 'application/pdf'}>
                      <FileTextOutlined />
                      <span>{file.name}</span>
                      {file.isSigned ? (
                        <Button
                          className={'digital-signature'}
                          type="text"
                          onClick={() => message.info('Đã ký!')}>
                          Đã ký
                        </Button>
                      ) : file.type !== 'application/pdf' ? (
                        <AccessControlAction
                          aclActionType={ACL_ACTION_TYPE.ky_so__INCOMING}>
                          <Button
                            className={'digital-signature'}
                            type="text"
                            onClick={() =>
                              message.info(
                                'Chỉ ký được file PDF, vui lòng chọn file khác!'
                              )
                            }>
                            Ký số
                          </Button>
                        </AccessControlAction>
                      ) : (
                        <AccessControlAction
                          aclActionType={ACL_ACTION_TYPE.ky_so__INCOMING}>
                          <Popconfirm
                            placement="top"
                            title={'Hãy chọn loại ký'}
                            onConfirm={() => handleOpenPopupSign(file, index)}
                            okText="Ký tùy chọn"
                            cancelText="Ký mặc đinh"
                            onCancel={() => handleSignSavis(file, index)}>
                            <Button
                              className={'digital-signature'}
                              type="text"
                              onClick={() => handleBeforeSign(file, index)}>
                              Ký số
                            </Button>
                          </Popconfirm>
                        </AccessControlAction>
                      )}
                      {
                        <Button
                          className={'rename'}
                          type="text"
                          onClick={() => {
                            setIsRenameFileFromTruc(false)
                            handleOpenPopupRenameFile(file, index)
                          }}>
                          Đổi tên
                        </Button>
                      }
                      <Tooltip title={'Xoá tập tin'}>
                        <DeleteOutlined
                          onClick={() =>
                            handleRemoveFileFromUploadList(file.uid, index)
                          }
                        />
                      </Tooltip>
                    </UploadFileListItem>
                  ))}
                  {fileFromTruc.map((file, index) => (
                    <UploadFileListItem key={file.fileId}>
                      <FileTextOutlined style={{ color: '1890FF' }} />
                      <span style={{ color: '#1890FF' }}>{file.name}</span>
                      <Button
                        className={'rename'}
                        type="text"
                        onClick={() => {
                          setIsRenameFileFromTruc(true)
                          handleOpenPopupRenameFile(file, index)
                        }}>
                        Đổi tên
                      </Button>
                      <Tooltip title={'Xoá tập tin'}>
                        <DeleteOutlined
                          onClick={() =>
                            handleRemoveFileFromTruc(file.fileId, index)
                          }
                        />
                      </Tooltip>
                    </UploadFileListItem>
                  ))}
                </UploadFileListWrapper>
              }

              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  gap: '30px',
                }}>
                <Form.Item
                  style={{ width: '100%' }}
                  label={'Cơ quan ban hành'}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn cơ quan ban hành!',
                    },
                  ]}
                  name={'authority_issued_id'}>
                  <Select
                    allowClear
                    showSearch
                    showArrow
                    filterOption={filterSelectOption}
                    notFoundContent={<EmptyContent />}
                    placeholder={'Chọn cơ quan ban hành'}>
                    {authorityIssuedsList.map(authorityIssued => (
                      <Option
                        name={authorityIssued.name}
                        value={authorityIssued.id}
                        key={authorityIssued.id}>
                        {authorityIssued.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Button
                  style={{ marginBottom: '10px' }}
                  onClick={handleToggleAuthoritiesActionModal(true)}>
                  Tạo mới
                </Button>
              </div>

              <Title level={5} style={{ margin: '10px 0 15px 0' }}>
                Thông tin phân phát
              </Title>
              <div
                style={{
                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px',
                  padding: '10px',
                }}>
                <Row type={'flex'} gutter={30}>
                  <Col md={24} lg={8}>
                    <SelectTitle>
                      <span>
                        <span style={{ marginRight: '10px' }}>
                          Lãnh đạo xử lý:
                        </span>
                      </span>
                      <Space>
                        <Tooltip title="Chọn người dùng" color={blue}>
                          <Button
                            icon={<UserOutlined style={{ color: blue }} />}
                            onClick={() =>
                              handleOpenSelectUserDepartmentPopup(
                                ASSIGNEE_TYPE.HANDLER
                              )
                            }
                          />
                        </Tooltip>
                      </Space>
                    </SelectTitle>
                    <SelectList>
                      {/*Render lãnh đạo xử lý*/}
                      {handlerList.map(el => (
                        <Tag
                          className={'tag-selected'}
                          color={'blue'}
                          key={el.id}
                          closable
                          onClose={() =>
                            selectPeopleStore.handleRemoveSelect(el)
                          }>
                          <AvatarHasName
                            imgId={el.image_id}
                            size={22}
                            name={el.full_name}
                            icon={
                              (el.assignee_type ===
                                ASSIGNEE_TYPE.DEPARTMENT && <BankOutlined />) ||
                              (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                                <TeamOutlined />
                              ))
                            }
                          />
                        </Tag>
                      ))}
                    </SelectList>
                  </Col>

                  <Col md={24} lg={8}>
                    <SelectTitle>
                      <span>
                        <span style={{ marginRight: '10px' }}>
                          Người xử lý:
                        </span>
                      </span>
                      <Space>
                        <Tooltip title="Chọn người dùng" color={blue}>
                          <Button
                            icon={<UserOutlined style={{ color: blue }} />}
                            onClick={() =>
                              handleOpenSelectUserDepartmentPopup(
                                ASSIGNEE_TYPE.COMBINER
                              )
                            }
                          />
                        </Tooltip>
                        <Tooltip title="Chọn nhóm" color={'#ffc069'}>
                          <Button
                            icon={<TeamOutlined />}
                            style={{ color: yellowPrimary }}
                            onClick={() =>
                              handleOpenSelectGroupPopup(ASSIGNEE_TYPE.COMBINER)
                            }
                          />
                        </Tooltip>
                      </Space>
                    </SelectTitle>
                    <SelectList>
                      {/*Render danh sách người xử lý*/}
                      {combinerList.map(el => (
                        <Tag
                          className={'tag-selected'}
                          color={'blue'}
                          key={el.id}
                          closable
                          onClose={() =>
                            selectPeopleStore.handleRemoveSelect(el)
                          }>
                          <AvatarHasName
                            imgId={el.image_id}
                            size={22}
                            name={el.full_name}
                            icon={
                              (el.assignee_type ===
                                ASSIGNEE_TYPE.DEPARTMENT && <BankOutlined />) ||
                              (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                                <TeamOutlined />
                              ))
                            }
                          />
                        </Tag>
                      ))}
                    </SelectList>
                  </Col>
                  <Col md={24} lg={8}>
                    <SelectTitle>
                      <span>
                        <span style={{ marginRight: '10px' }}>
                          Phối hợp/ Theo dõi:
                        </span>
                      </span>
                      <Space>
                        <Tooltip title="Chọn người dùng" color={blue}>
                          <Button
                            icon={<UserOutlined style={{ color: blue }} />}
                            onClick={() =>
                              handleOpenSelectUserDepartmentPopup(
                                ASSIGNEE_TYPE.FOLLOWER
                              )
                            }
                          />
                        </Tooltip>
                        <Tooltip title="Chọn nhóm" color={'#ffc069'}>
                          <Button
                            icon={<TeamOutlined />}
                            style={{ color: yellowPrimary }}
                            onClick={() =>
                              handleOpenSelectGroupPopup(ASSIGNEE_TYPE.FOLLOWER)
                            }
                          />
                        </Tooltip>
                      </Space>
                    </SelectTitle>
                    <SelectList>
                      {/*Render danh sách người phối hợp và theo dõi*/}
                      {followerList.map(el => (
                        <Tag
                          className={'tag-selected'}
                          color={'blue'}
                          key={el.id}
                          closable
                          onClose={() =>
                            selectPeopleStore.handleRemoveSelect(el)
                          }>
                          <AvatarHasName
                            imgId={el.image_id}
                            size={22}
                            name={el.full_name}
                            icon={
                              (el.assignee_type ===
                                ASSIGNEE_TYPE.DEPARTMENT && <BankOutlined />) ||
                              (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                                <TeamOutlined />
                              ))
                            }
                          />
                        </Tag>
                      ))}
                    </SelectList>
                  </Col>
                </Row>
                <Row type={'flex'} gutter={30}>
                  <Col md={24} lg={8}>
                    <Form.Item
                      label={'Thời gian phân phát'}
                      name={'date_distribution'}>
                      <RangePicker
                        placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                        format={DATE_FORMAT_LIST}
                        style={{ width: '100%' }}
                        onChange={handleDateChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <FormActionFooter>
                <Button onClick={handleCancelCreate}>Huỷ bỏ</Button>
                <Button
                  style={{ marginLeft: 10 }}
                  type={'primary'}
                  htmlType={'submit'}>
                  Tạo văn bản
                </Button>
              </FormActionFooter>
            </Form>
          </Container>
        </ContentBlockWrapper>
        <SelectPeoplePopup
          onlyOneUser={
            selectPeopleStore.assigneePopupType === ASSIGNEE_TYPE.HANDLER
          }
          handleCancelSelectUser={handleCancelSelectUserDepartment}
          handleSubmitSelectUser={() =>
            setIsVisibleSelectUserDepartmentPopup(false)
          }
          isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
        />
        <SelectGroupPopup
          isVisibleSelectGroupPopup={isVisibleSelectGroupPopup}
          handleCancelSelectGroup={handleCancelSelectGroup}
          handleSubmitSelectGroup={() => setIsVisibleSelectGroupPopup(false)}
        />
        {renderDocument()}
        <PopupSign
          isVisiblePopupSign={isVisiblePopupSign}
          handleClosePopupSign={handleClosePopupSign}
          hiddenSelectDocument
        />
        <PopupRenameFile
          isModalVisibleRenameFile={isModalVisibleRenameFile}
          handleCloseModalRenameFile={() => setIsModalVisibleRenameFile(false)}
          submitRenameFile={submitRenameFile}
          fieldsRenameFile={fieldsRenameFile}
          fileExt={fileExt}
        />
      </DashboardLayout>

      {isVisibleAuthoritiesActionModal && (
        <AuthoritiesActionModal
          disabledAuthoritiesTypePicker
          tabActive={DOCUMENT_TYPE.INCOMING}
          payloadModal={{
            status: true,
            isCreate: true,
            updatePayLoad: null,
          }}
          handleCloseModal={handleToggleAuthoritiesActionModal(false)}
        />
      )}
    </>
  )
}

InternalDocumentIncomingDocCreatePage.propTypes = {}

export default memo(
  inject(
    'commonStore',
    'internalDocumentStore',
    'fileStore',
    'authorityStore',
    'loadingAnimationStore',
    'companyStore',
    'bookStore',
    'selectPeopleStore',
    'staffStore',
    'departmentStore',
    'taskManagementStore',
    'connectedDocumentStore',
    'signedDocumentStore'
  )(observer(InternalDocumentIncomingDocCreatePage))
)
