import { CloseOutlined, UserOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  Tooltip,
  TreeSelect,
  message,
} from 'antd'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
import React, { useEffect, useRef, useState } from 'react'
import { blue } from '../../color'
import SelectListComponentRisk from '../../components/Common/SelectListComponentRisk'
import SelectUserButton from '../../components/Common/SelectUserButton'
import RichEditor from '../../components/RichEditor/RichEditor'
import SelectPeoplePopup from '../../components/SelectPeoplePopup'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'
import { systemName } from '../../config'
import { ASSIGNEE_TYPE } from '../../constants'
import useOpenItem from '../../hooks/useOpenItem'
import utils from '../../utils'
import { FormActionFooter } from '../ConnectedDocumentIncomingDocCreatePage/ConnectedDocumentIncomingDocCreatePageStyled'
import { SelectPeopleWrapper } from '../WorkPage/PopupVote/PopupVoteStyled'
import { RiskSelects } from './RiskCreatePageStyled'
import RuiRoNhanDienTemplate from './RuiRoNhanDienTemplate'
import RuiRoNhanDienTemplatePropose from './RuiRoNhanDienTemplatePropose'
import { UserTagV2 } from '../../components/RiskSelecUsertList/RiskSelecUsertListStyle'
import SelectPeoplePopupLive from '../../components/SelectPeoplePopupLive'
import loadingAnimationStore from '../../stores/loadingAnimationStore'

const SelectUserRiskItem = ({ userList = [], riskUnitsV2 = [] }) => {
  // tìm chức danh của user
  const resultListUser =
    Array.isArray(riskUnitsV2) &&
    riskUnitsV2.flatMap(unit =>
      unit.unit_users
        .filter(userUnitsV2 =>
          userList?.some(
            itemUserList => itemUserList.username === userUnitsV2.username
          )
        )
        .map(userUnitsV2 => {
          const matchingUserList = userList.find(
            itemUserList => itemUserList.username === userUnitsV2.username
          )
          return {
            user_code: userUnitsV2.user_code,
            full_name: userUnitsV2.full_name,
            username: userUnitsV2.username,
            position_name:
              userUnitsV2.position_name || matchingUserList.position_name,
          }
        })
    )

  return (
    <>
      {resultListUser?.length > 0
        ? resultListUser?.map((item, index) => {
            return (
              <div key={index} style={{ marginTop: 4, height: '30px' }}>
                <UserTagV2>
                  {utils.getNameInCapitalize(
                    item?.full_name || item?.title || item?.label
                  )}{' '}
                  {item?.position_name ? (
                    <>
                      - <span>{item?.position_name}</span>
                    </>
                  ) : null}
                </UserTagV2>
              </div>
            )
          })
        : userList?.map((item, index) => {
            return (
              <div key={index} style={{ marginTop: 4, height: '30px' }}>
                <UserTagV2>
                  {utils.getNameInCapitalize(
                    item?.full_name || item?.title || item?.label
                  )}{' '}
                  {item?.position_name ? (
                    <>
                      - <span>{item?.position_name}</span>
                    </>
                  ) : null}
                </UserTagV2>
              </div>
            )
          })}
    </>
  )
}

const RiskIdentificationCreatePopup = ({
  handleCancel,
  isVisible,
  actionStore,
  riskStore,
  getRiskDetail,
  selectUserList,
  type,
  setType,
  selectPeopleStore,
  handleVisibleSelectOneUserPopup,
  riskUnitsV2,
  departmentStore,
  dataRiskUnitsByTypeNDDPV,
  dataRiskUnitsByType,
  departmentWithUserMasterList,
  companyStore,
}) => {
  const { riskDetail, ruiRoNhanDienList } = riskStore
  const {
    indexDetailAction,
    dataActions,
    setIndexDetailAction,
    setDataActions,
    createAction,
    updateAction,
  } = actionStore
  const {
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleOpenSelectGroupPopup,
    handleCancelSelectGroup,
    isVisibleSelectUserDepartmentPopup,
    setSelectUserData,
    selectUserData,
    departmentWithUserList,
    getDepartmentWithUsersLive,
  } = selectPeopleStore

  const { SHOW_ALL } = TreeSelect
  const treeData =
    Array.isArray(riskUnitsV2) &&
    riskUnitsV2?.map(item => ({
      title: item.name,
      value: item.code,
      key: item.code,
      children: item.unit_users?.map(user => ({
        title: utils?.getNameInCapitalize(user.full_name),
        value: user.user_code,
        key: user.user_code,
        position_name: user.position_name,
      })),
    }))
  const treeData1 = dataRiskUnitsByType?.map(item => ({
    title: item.name,
    value: item.code,
    key: item.code,
    children: item.unit_users.map(user => ({
      title: user.full_name,
      value: user.user_code,
      key: user.user_code,
      position_name: user.position_name,
    })),
  }))
  const treeData2 = departmentWithUserMasterList?.map(item => ({
    title: item.name,
    value: item.code,
    key: item.code,
    children: item.users.map(user => ({
      title: utils.getNameInCapitalize(user.name_uppercase),
      value: user.user_code,
      key: user.user_code,
      position_name: user.position_name,
    })),
  }))
  const treeData3 = dataRiskUnitsByTypeNDDPV?.map(item => ({
    title: item.name,
    value: item.code,
    key: item.code,
    children: item?.unit_users?.map(user => ({
      title: user.full_name,
      value: user.user_code,
      key: user.user_code,
      position_name: user.position_name,
    })),
  }))

  const treeDataDp = departmentWithUserList.map(item => ({
    title: item.name,
    value: item.code,
    key: item.code,
    children: item.users.map(user => ({
      title: utils.getNameInCapitalize(user.name_uppercase),
      value: user.user_code,
      key: user.user_code,
    })),
  }))

  const [treeData_BPDX, setTreeData] = useState([])
  const [treeData_BP, setTreeDataBP] = useState([])
  const [treeData_LD, setTreeDataLD] = useState([])
  const [listChildren, setListChildren] = useState([])
  useEffect(() => {
    const updatedTreeData = treeData2?.concat(treeData1)
    setTreeData(updatedTreeData)
    const updatedTreeDataBP = treeData2?.concat(treeData)
    setTreeDataBP(updatedTreeDataBP)
    const updatedTreeDataLD = treeData2?.concat(treeData3)
    setTreeDataLD(updatedTreeDataLD)
  }, [riskUnitsV2, departmentWithUserList])

  const queryStringParse = queryString?.parse(location.search)
  const { companyList } = companyStore
  const [
    isVisibleSelectPeopleExecutorPopup,
    setIsVisibleSelectPeopleExecutorPopup,
  ] = useState(false)
  const [riskPoint, setRiskPoint] = useState({ p: [], s: [], r: [] })
  const [isRenderRichText, setIsRenderRichText] = useState(false)
  const [
    isVisibleRuiRoNhanDienTemplate,
    setIsVisibleRuiRoNhanDienTemplate,
  ] = useState(false)
  const [
    isVisibleRuiRoNhanDienTemplates,
    setIsVisibleRuiRoNhanDienTemplates,
  ] = useState(false)
  const [ruiRoNhanDien, setRuiRoNhanDien] = useState([])
  const [ruiRoNhanDienPropose, setRuiRoNhanDienPropose] = useState([])

  const [form] = Form.useForm()
  const CONTENT_REF = useRef(null)
  const NDRR_REF = useRef(null)
  const LRRDX1_REF = useRef(null)

  const XDGPGTPN_REF = useRef(null)
  const BPXL_REF = useRef(null)
  const LRRDX_REF = useRef(null)

  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedCqvp, setSelectedCqvp] = useState(null)
  const [selectedRuiRoNhanDien, setSelectedRuiRoNhanDien] = useState([])
  const [
    selectedRuiRoNhanDienPropose,
    setSelectedRuiRoNhanDienPropose,
  ] = useState([])
  const prevRuiRoNhanDienRef = useRef([])
  useEffect(() => {
    // Tìm các id đã thay đổi

    let changedIds
    if (prevRuiRoNhanDienRef?.current) {
      changedIds = prevRuiRoNhanDienRef?.current
        ?.filter(
          item => !ruiRoNhanDien.some(ruiRoItem => ruiRoItem.id === item.id)
        )
        .map(item => item.id)
    }
    if (
      prevRuiRoNhanDienRef?.current?.length > 0 &&
      ruiRoNhanDien?.length === 0
    ) {
      setRiskPoint({ p: [], s: [], r: [] })
      return
    }
    // Xóa các id đã thay đổi khỏi riskPoint
    if (changedIds?.length > 0) {
      const updateFieldValues = (field, data) => {
        if (data[field] === undefined) return

        if (typeof data[field] === 'string') {
          form.setFieldsValue({ [field]: data[field] })
        } else if (Array.isArray(data[field])) {
          const newRiskPoint = { ...riskPoint }
          data[field].forEach((item, index) => {
            if (changedIds.includes(item.id)) {
              form.setFieldsValue({
                [`${field}[${index}]`]: null,
              })
              newRiskPoint[field][index] = { id: undefined, value: undefined }
            } else {
              form.setFieldsValue({
                [`${field}[${index}]`]: null,
              })
              newRiskPoint[field][index] = { id: undefined, value: undefined }
            }
          })
          setRiskPoint(newRiskPoint)
        }
      }

      const fields = ['p', 's', 'r']

      fields.forEach(field => {
        updateFieldValues(field, riskPoint)
      })

      const updatedRiskPoint = { ...riskPoint }
      Object.keys(updatedRiskPoint).forEach(key => {
        updatedRiskPoint[key] = updatedRiskPoint[key].filter(
          item => !changedIds.includes(item.id)
        )
      })
      setRiskPoint(updatedRiskPoint)
    }
    // Cập nhật giá trị trước
    prevRuiRoNhanDienRef.current = ruiRoNhanDien
  }, [ruiRoNhanDien])

  const [selectedList, setSelectedList] = useState([])

  useEffect(() => {
    setSelectedOption(renderTypeToOption(queryStringParse?.type))
  }, [])

  useEffect(() => {
    if (!dataActions?.tier1[0] || indexDetailAction?.tier !== '1') {
      return
    }
    let fillData = dataActions?.tier1[0]

    const BAN_CMNV_VIMC = departmentWithUserList?.find(
      depart => depart?.name === fillData?.III_kiem_soat_rui_ro?.phong_ban_nddpv
    )

    setSelectUserData({
      ...selectUserData,
      [ASSIGNEE_TYPE.USER]:
        fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
          .lanh_dao_don_vi_nddpv_phu_trach_chung,
      [ASSIGNEE_TYPE.lanh_dao_vimc]: fillData.II_xu_ly_rui_ro.lanh_dao_vimc,
    })
    let selected_doi_tuong = null
    if (type == 'NDDPV') selected_doi_tuong = 'nddpv_cua_vimc_tai_dntv'
    if (type == 'DVPT') selected_doi_tuong = 'dvpt_cua_vimc'
    if (type == 'CQVP') selected_doi_tuong = 'cac_don_vi_thuoc_cq_vp_cua_vimc'
    setSelectedOption(selected_doi_tuong)
    setSelectedRuiRoNhanDien(dataActions.tier1[0]?.other?.nhan_dien_rui_ro)
    setRuiRoNhanDien(dataActions.tier1[0]?.other?.nhan_dien_rui_ro)
    setSelectedRuiRoNhanDienPropose(dataActions.tier1[0]?.other?.rui_ro_de_xuat)
    setSelectedList(
      fillData?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.lanh_dao_don_vi_phu_thuoc
    )
    form.setFieldsValue({
      //doi_tuong_thuc_hien
      group_doi_tuong: selected_doi_tuong,
      nddpv_cua_vimc_tai_dntv:
        fillData?.doi_tuong_thuc_hien?.nddpv_cua_vimc_tai_dntv,
      department_code_nddpv_cua_vimc_tai_dntv:
        fillData?.doi_tuong_thuc_hien?.department_code_nddpv_cua_vimc_tai_dntv,
      dvpt_cua_vimc: fillData?.doi_tuong_thuc_hien?.dvpt_cua_vimc,
      cac_don_vi_thuoc_cq_vp_cua_vimc:
        fillData?.doi_tuong_thuc_hien?.cac_don_vi_thuoc_cq_vp_cua_vimc,
      //I_nhan_dien_rui_ro_va_danh_gia_rui_ro -> cong_viec_linh_vuc_hoat_dong
      cong_viec_theo:
        fillData?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
          ?.cong_viec_linh_vuc_hoat_dong.cong_viec_theo,
      noi_dung_cong_viec_khac_ko_thuoc_cac_nhom_tren:
        fillData?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
          ?.cong_viec_linh_vuc_hoat_dong
          ?.noi_dung_cong_viec_khac_ko_thuoc_cac_nhom_tren,
      //I_nhan_dien_rui_ro_va_danh_gia_rui_ro -> cong_viec_thuc_hien
      noi_dung_cong_viec:
        fillData?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.cong_viec_thuc_hien
          ?.noi_dung_cong_viec,
      nguoi_thuc_hien__cong_viec:
        fillData?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.cong_viec_thuc_hien
          ?.nguoi_thuc_hien,
      thoi_gian_hoan_thanh:
        fillData?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.cong_viec_thuc_hien
          ?.thoi_gian_hoan_thanh,
      //I_nhan_dien_rui_ro_va_danh_gia_rui_ro -> nhan_dien_rui_ro_va_danh_gia_rui_ro
      // nhan_dien_rui_ro:
      //   fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
      //     .nhan_dien_rui_ro_va_danh_gia_rui_ro.nhan_dien_rui_ro,
      // xay_dung_giai_phap_giam_thieu_phong_ngua:
      //   fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
      //     .nhan_dien_rui_ro_va_danh_gia_rui_ro
      //     .xay_dung_giai_phap_giam_thieu_phong_ngua,
      // p:
      //   fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
      //     .nhan_dien_rui_ro_va_danh_gia_rui_ro.p,
      // s:
      //   fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
      //     .nhan_dien_rui_ro_va_danh_gia_rui_ro.s,
      // r:
      //   fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
      //     .nhan_dien_rui_ro_va_danh_gia_rui_ro.r,
      //

      nguoi_thuc_hien__sign:
        fillData?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.nguoi_thuc_hien_sign,
      lanh_dao_phong_dvpt:
        fillData?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.lanh_dao_phong_dvpt,
      lanh_dao_phong_dvpt__sign:
        fillData?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
          ?.lanh_dao_phong_dvpt__sign,
      nguoi_thuc_hien__signed:
        fillData?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.nguoi_thuc_hien_signed,
      lanh_dao_phong_dvpt__signed:
        fillData?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
          ?.lanh_dao_phong_dvpt__signed,
      nguoi_thuc_hien_dvpt_y_kien:
        fillData?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
          ?.nguoi_thuc_hien_dvpt_y_kien,
      lanh_dao_dvpt_y_kien:
        fillData?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.lanh_dao_dvpt_y_kien,
      nguoi_thuc_hien__sign_khac:
        fillData?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
          ?.nguoi_thuc_hien_sign_khac,
      // lanh_dao_don_vi_nddpv_phu_trach_chung:
      //   fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
      //     .lanh_dao_don_vi_nddpv_phu_trach_chung,
      //II_xu_ly_rui_ro -> de_xuat_loai_rui_ro_can_xu_ly
      loai_rui_ro_de_xuat:
        fillData?.II_xu_ly_rui_ro?.de_xuat_loai_rui_ro_can_xu_ly
          .loai_rui_ro_de_xuat,
      loai_rui_ro_de_xuat_khac:
        fillData?.II_xu_ly_rui_ro.de_xuat_loai_rui_ro_can_xu_ly
          ?.loai_rui_ro_de_xuat_khac,
      bo_phan_de_xuat:
        fillData?.II_xu_ly_rui_ro?.de_xuat_loai_rui_ro_can_xu_ly
          ?.bo_phan_de_xuat,
      bo_phan_de_xuat_khac:
        fillData?.II_xu_ly_rui_ro?.de_xuat_loai_rui_ro_can_xu_ly
          .bo_phan_de_xuat_khac,
      bien_phap_xu_ly:
        fillData?.II_xu_ly_rui_ro.de_xuat_loai_rui_ro_can_xu_ly
          ?.bien_phap_xu_ly,
      nguoi_thuc_hien__de_xuat:
        fillData?.II_xu_ly_rui_ro?.de_xuat_loai_rui_ro_can_xu_ly
          ?.nguoi_thuc_hien,
      nguoi_thuc_hien__de_xuat_khac:
        fillData?.II_xu_ly_rui_ro?.de_xuat_loai_rui_ro_can_xu_ly
          ?.nguoi_thuc_hien__de_xuat_khac,
      //
      lanh_dao_quyet_dinh_bien_phap_xu_ly:
        fillData?.II_xu_ly_rui_ro?.lanh_dao_quyet_dinh_bien_phap_xu_ly,
      don_vi_thuc_hien_xu_ly_rui_ro:
        fillData?.II_xu_ly_rui_ro?.don_vi_thuc_hien_xu_ly_rui_ro,
      don_vi_thuc_hien_xu_ly_rui_ro__signed:
        fillData?.II_xu_ly_rui_ro?.don_vi_thuc_hien_xu_ly_rui_ro__signed,
      don_vi_thuc_hien_xu_ly_rui_ro_khac:
        fillData?.II_xu_ly_rui_ro?.don_vi_thuc_hien_xu_ly_rui_ro_khac,
      //DVPT
      pho_giam_doc_phu_trach: fillData?.II_xu_ly_rui_ro?.pho_giam_doc_phu_trach,
      pho_giam_doc_phu_trach__signed:
        fillData?.II_xu_ly_rui_ro?.pho_giam_doc_phu_trach__signed,
      bo_phan_quan_ly_rui_ro_cua_don_vi:
        fillData?.II_xu_ly_rui_ro?.bo_phan_quan_ly_rui_ro_cua_don_vi,
      // lanh_dao_vimc: fillData.II_xu_ly_rui_ro.lanh_dao_vimc,
      nddpv_phu_trach_chung__signed:
        fillData?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
          ?.nddpv_phu_trach_chung__signed,
      nddpv_phu_trach_chung:
        fillData?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.nddpv_phu_trach_chung,
      nguoi_dai_dien_phan_von_phu_trach_chung:
        fillData?.II_xu_ly_rui_ro?.nguoi_dai_dien_phan_von_phu_trach_chung,
      nguoi_dai_dien_phan_von_phu_trach_chung__signed:
        fillData?.II_xu_ly_rui_ro
          ?.nguoi_dai_dien_phan_von_phu_trach_chung__signed,
      lanh_dao_don_vi_phu_thuoc:
        fillData?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
          ?.lanh_dao_don_vi_phu_thuoc,
      lanh_dao_don_vi_phu_thuoc_cqvp:
        fillData?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
          ?.lanh_dao_don_vi_phu_thuoc_cqvp,
      //KSRR
      doi_voi_cac_loai_rui_ro_da_nhan_dien__dong_y:
        fillData?.III_kiem_soat_rui_ro?.doi_voi_cac_loai_rui_ro_da_nhan_dien
          ?.dong_y,
      doi_voi_cac_loai_rui_ro_da_nhan_dien__khong_dong_y:
        fillData?.III_kiem_soat_rui_ro?.doi_voi_cac_loai_rui_ro_da_nhan_dien
          ?.khong_dong_y,

      doi_voi_cac_loai_rui_ro_da_nhan_dien__dieu_chinh_bo_sung:
        fillData?.III_kiem_soat_rui_ro?.doi_voi_cac_loai_rui_ro_da_nhan_dien
          ?.dieu_chinh_bo_sung,
      //III_kiem_soat_rui_ro -> doi_voi_danh_gia_muc_rui_ro
      doi_voi_danh_gia_muc_rui_ro__dong_y:
        fillData?.III_kiem_soat_rui_ro?.doi_voi_danh_gia_muc_rui_ro?.dong_y,
      doi_voi_danh_gia_muc_rui_ro__khong_dong_y:
        fillData?.III_kiem_soat_rui_ro?.doi_voi_danh_gia_muc_rui_ro
          ?.khong_dong_y,
      doi_voi_danh_gia_muc_rui_ro__dieu_chinh_bo_sung:
        fillData?.III_kiem_soat_rui_ro?.doi_voi_danh_gia_muc_rui_ro
          ?.dieu_chinh_bo_sung,
      //
      loai_rui_ro_da_de_xuat_xu_ly:
        fillData?.III_kiem_soat_rui_ro?.loai_rui_ro_da_de_xuat_xu_ly,
      danh_gia_bien_phap_xu_ly_da_de_xuat:
        fillData?.III_kiem_soat_rui_ro?.danh_gia_bien_phap_xu_ly_da_de_xuat,
      thoi_gian_hoan_thanh_cong_viec_duoc_giao:
        fillData?.III_kiem_soat_rui_ro
          ?.thoi_gian_hoan_thanh_cong_viec_duoc_giao,
      y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc:
        fillData?.III_kiem_soat_rui_ro
          ?.y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc,
      bo_phan_neu_y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc:
        fillData?.III_kiem_soat_rui_ro
          ?.bo_phan_neu_y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc,
      chuyen_vien_thuc_hien:
        fillData?.III_kiem_soat_rui_ro?.chuyen_vien_thuc_hien,
      lanh_dao_ban: fillData?.III_kiem_soat_rui_ro?.lanh_dao_ban,
      lanh_dao_ban_nddpv: fillData?.III_kiem_soat_rui_ro?.lanh_dao_ban_nddpv,
      lanh_dao_ban_cqvp: fillData?.III_kiem_soat_rui_ro?.lanh_dao_ban_cqvp,
      lanh_dao_ban_signed: fillData?.III_kiem_soat_rui_ro?.lanh_dao_ban_signed,
      lanh_dao_phong_pcathh:
        fillData?.III_kiem_soat_rui_ro?.lanh_dao_phong_pcathh,
      lanh_dao_phong_pcathh__signed:
        fillData?.III_kiem_soat_rui_ro?.lanh_dao_phong_pcathh__signed,

      phong_ban_nddpv: fillData?.III_kiem_soat_rui_ro?.phong_ban_nddpv,
      chuyen_vien_thuc_hien_nddpv: BAN_CMNV_VIMC?.users
        ?.filter(item =>
          fillData?.III_kiem_soat_rui_ro?.chuyen_vien_thuc_hien_nddpv?.includes(
            item.user_code
          )
        )
        ?.map(item => item.user_code),
      chuyen_vien_thuc_hien_nddpv_signed:
        fillData?.III_kiem_soat_rui_ro?.chuyen_vien_thuc_hien_nddpv_signed,
      lanh_dao_ban_nddpv2: BAN_CMNV_VIMC?.users
        ?.filter(item =>
          fillData.III_kiem_soat_rui_ro.lanh_dao_ban_nddpv2?.includes(
            item.user_code
          )
        )
        ?.map(item => item.user_code),
    })

    const updateFieldValues = (field, data) => {
      if (data[field] === undefined) return

      if (typeof data[field] === 'string') {
        form.setFieldsValue({ [field]: data[field] })
      } else if (Array.isArray(data[field])) {
        const newRiskPoint = { ...riskPoint }
        data[field].forEach((item, index) => {
          form.setFieldsValue({
            [`${field}[${index}]`]: item?.value?.toString(),
          })
          newRiskPoint[field][index] = { id: item?.id, value: item?.value }
        })
      }
    }

    const fields = ['p', 's', 'r']

    fields.forEach(field => {
      updateFieldValues(
        field,
        fillData?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
          ?.nhan_dien_rui_ro_va_danh_gia_rui_ro
      )
    })

    // console.log('submitData', fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro.nhan_dien_rui_ro_va_danh_gia_rui_ro)
  }, [indexDetailAction])

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setIsRenderRichText(true)
      }, 400)
    } else {
      setIsRenderRichText(false)
    }
  }, [isVisible])

  const p = parseInt(
    dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
      ?.nhan_dien_rui_ro_va_danh_gia_rui_ro?.p
  )

  const s = parseInt(
    dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
      ?.nhan_dien_rui_ro_va_danh_gia_rui_ro?.s
  )

  // useEffect(() => {
  //   const P = riskPoint.p ? riskPoint.p : p
  //   const S = riskPoint.s ? riskPoint.s : s
  //   if (!P || !S) return
  //   const r = Math.ceil(P * S)
  //   const displayedR = r >= 1 && r <= 5 ? '1' : r >= 6 && r <= 15 ? '2' : '3'
  //   form.setFieldsValue({
  //     r: displayedR,
  //     loai_rui_ro_de_xuat: displayedR,
  //   })
  // }, [riskPoint])
  useEffect(() => {
    if (!riskPoint.p && !riskPoint.s) return

    const newRiskPoint = { ...riskPoint }
    let riskChanged = false
    const length = ruiRoNhanDien?.length
    if (type === 'NDDPV') {
      let P = riskPoint.p[0]?.value
      let S = riskPoint.s[0]?.value
      if (P && S) {
        const r = Math.ceil(P * S)
        const displayedR =
          r >= 1 && r <= 5 ? '1' : r >= 6 && r <= 15 ? '2' : '3'
        if (newRiskPoint.r[0]?.value !== +displayedR) {
          riskChanged = true
          form.setFieldsValue({
            [`r[${0}]`]: displayedR,
            // loai_rui_ro_de_xuat: displayedR,
          })
          newRiskPoint.r[0] = { id: null, value: +displayedR }
        }
      }
    } else {
      for (let i = 0; i < length; i++) {
        let P = riskPoint.p[i]?.value
        let S = riskPoint.s[i]?.value
        if (P && S) {
          const r = Math.ceil(P * S)
          const displayedR =
            r >= 1 && r <= 5 ? '1' : r >= 6 && r <= 15 ? '2' : '3'
          if (newRiskPoint.r[i]?.value !== +displayedR) {
            riskChanged = true
            form.setFieldsValue({
              [`r[${i}]`]: displayedR,
              // loai_rui_ro_de_xuat: displayedR,
            })
            newRiskPoint.r[i] = { id: ruiRoNhanDien[i].id, value: +displayedR }
          }
        }
      }
    }

    if (riskChanged) {
      setRiskPoint(newRiskPoint)
    }
  }, [riskPoint])

  useEffect(() => {
    if (ruiRoNhanDienList?.length > 0) {
      setRuiRoNhanDien(ruiRoNhanDienList)
    }
    return () => {
      setRiskPoint({ p: [], s: [], r: [] })
    }
  }, [])

  // Rủi ro nhận diện
  const dataNDRR = [] // Nhận diện rủi ro
  const dataLRRDX = [] // Loại rủi ro đề xuất
  const dataXDGPGTPN = [] // Xây dựng giải pháp giảm thiểu phòng ngừa
  const causesOfIdentifiedRisks = [] // Nguyên nhân
  const dataXDGPGTPNII = [] // Xây dựng giải pháp giảm thiểu phòng ngừa II
  // Xử lý rủi ro
  const causesOfHandledRisks = [] // Nguyên nhân

  useEffect(() => {
    if (ruiRoNhanDien?.length > 0) {
      ruiRoNhanDien?.map(item => {
        dataNDRR.push(
          `${item.rui_ro_nhan_dien ?? item.identification_risk} <br/>`
        )
        causesOfIdentifiedRisks.push(
          `${item.nguyen_nhan ?? item.reason ?? ''} <br>`
        )
        dataXDGPGTPN.push(`${item.bien_phap_phong_ngua_giam_thieu ?? ''} <br>`)
      })
      NDRR_REF.current?.editor.setData(`${dataNDRR.join('')}`)
      XDGPGTPN_REF.current?.editor.setData(
        `${dataXDGPGTPN.join('').replace(/<\/?p>/g, '')}`
      )
    } else {
      NDRR_REF.current?.editor.setData(`<p>${''}</p>`)
      XDGPGTPN_REF.current?.editor.setData(`<p>${''}</p>`)
    }
  }, [ruiRoNhanDien])

  useEffect(() => {
    if (ruiRoNhanDienPropose.length > 0) {
      ruiRoNhanDienPropose?.map(item => {
        dataLRRDX.push(
          `${
            item.rui_ro_nhan_dien
              ? item.rui_ro_nhan_dien
              : item.identification_risk
          } <br/>`
        )
        causesOfHandledRisks.push(
          `${item.nguyen_nhan ?? item.reason ?? ''} <br>`
        )
        dataXDGPGTPNII.push(
          `${item.bien_phap_phong_ngua_giam_thieu ?? ''} <br>`
        )
      })
      LRRDX1_REF.current?.editor.setData(`${dataLRRDX.join('')}`)
      BPXL_REF.current?.editor.setData(
        `${dataXDGPGTPNII.join('').replace(/<\/?p>/g, '')}`
      )
    } else {
      LRRDX1_REF.current?.editor.setData(`<p>${''}</p>`)
      BPXL_REF.current?.editor.setData(`<p>${''}</p>`)
    }
  }, [ruiRoNhanDienPropose])
  const handleCancelModal = () => {
    handleCancel()
    // setRiskPoint({ p: [], s: [], r: [] })
    // setTimeout(() => {
    //   setIndexDetailAction({ tiers: null, index: null })
    // }, 500)
  }
  const renderUserFullNames = userList => {
    if (!userList || userList.length === 0) {
      return ''
    }

    const fullNames = userList.map(user => user.full_name)
    return fullNames.join(', ')
  }
  const handleCreateRiskId = async value => {
    const submitData = {
      name: 'Tuyến phòng vệ thứ nhất',
      doi_tuong_thuc_hien: {
        nddpv_cua_vimc_tai_dntv:
          type == 'NDDPV'
            ? selectUserData.nddpv_vimc.length > 0
              ? renderUserFullNames(selectUserData.nddpv_vimc)
              : value?.nddpv_cua_vimc_tai_dntv
            : '',
        department_code_nddpv_cua_vimc_tai_dntv:
          type == 'NDDPV' ? value?.department_code_nddpv_cua_vimc_tai_dntv : '',
        dvpt_cua_vimc:
          type == 'DVPT'
            ? selectUserData.dvpt_vimc.length > 0
              ? renderUserFullNames(selectUserData.dvpt_vimc)
              : value?.dvpt_cua_vimc
            : '',
        cac_don_vi_thuoc_cq_vp_cua_vimc:
          type == 'CQVP'
            ? selectUserData.dv_cqvp_vimc.length > 0
              ? renderUserFullNames(selectUserData.dv_cqvp_vimc)
              : value?.cac_don_vi_thuoc_cq_vp_cua_vimc
            : '',
      },
      I_nhan_dien_rui_ro_va_danh_gia_rui_ro: {
        cong_viec_linh_vuc_hoat_dong: {
          cong_viec_theo: value.cong_viec_theo,
          noi_dung_cong_viec_khac_ko_thuoc_cac_nhom_tren:
            value.noi_dung_cong_viec_khac_ko_thuoc_cac_nhom_tren,
        },
        cong_viec_thuc_hien: {
          noi_dung_cong_viec: CONTENT_REF.current.editor.getData(),
          nguoi_thuc_hien: value.nguoi_thuc_hien__cong_viec,
          thoi_gian_hoan_thanh: value.thoi_gian_hoan_thanh,
        },
        nhan_dien_rui_ro_va_danh_gia_rui_ro: {
          nhan_dien_rui_ro: NDRR_REF.current.editor.getData(),
          xay_dung_giai_phap_giam_thieu_phong_ngua: XDGPGTPN_REF.current.editor.getData(),
          nguyen_nhan: causesOfIdentifiedRisks.join(''),
          // danh_gia_rui_ro: value.danh_gia_rui_ro,
          // p: value.p,
          // s: value.s,
          // r: value.r,
          p: riskPoint.p,
          s: riskPoint.s,
          r: riskPoint.r,
        },
        nguoi_thuc_hien_sign: value.nguoi_thuc_hien__sign,
        lanh_dao_phong_dvpt: value.lanh_dao_phong_dvpt,
        lanh_dao_phong_dvpt__signed:
          checked_ldp_dvpt === true ? value.lanh_dao_phong_dvpt__signed : null,
        nguoi_thuc_hien_signed:
          checked_nth === true ? value.nguoi_thuc_hien__signed : null,
        nguoi_thuc_hien_dvpt_y_kien: value.nguoi_thuc_hien_dvpt_y_kien,
        lanh_dao_dvpt_y_kien: value.lanh_dao_dvpt_y_kien,
        nguoi_thuc_hien_sign_khac: value.nguoi_thuc_hien__sign_khac,
        lanh_dao_don_vi_nddpv_phu_trach_chung: selectUserList.USER,
        nddpv_phu_trach_chung: value.nddpv_phu_trach_chung,
        nddpv_phu_trach_chung__signed:
          checked_ptc === true ? value.nddpv_phu_trach_chung__signed : null,
        // lanh_dao_don_vi_phu_thuoc: value.lanh_dao_don_vi_phu_thuoc,
        lanh_dao_don_vi_phu_thuoc: selectedList,
        lanh_dao_don_vi_phu_thuoc_cqvp: value.lanh_dao_don_vi_phu_thuoc_cqvp,
      },
      II_xu_ly_rui_ro: {
        de_xuat_loai_rui_ro_can_xu_ly: {
          loai_rui_ro_de_xuat: LRRDX1_REF.current.editor.getData(),
          loai_rui_ro_de_xuat_khac: value.loai_rui_ro_de_xuat_khac,
          bo_phan_de_xuat: value.bo_phan_de_xuat,
          bo_phan_de_xuat_khac: value.bo_phan_de_xuat_khac,
          bien_phap_xu_ly: BPXL_REF.current.editor.getData(),
          nguoi_thuc_hien: value.nguoi_thuc_hien__de_xuat,
          nguoi_thuc_hien__de_xuat_khac: value.nguoi_thuc_hien__de_xuat_khac,
          nguyen_nhan: causesOfHandledRisks.join(''),
        },
        lanh_dao_quyet_dinh_bien_phap_xu_ly:
          value.lanh_dao_quyet_dinh_bien_phap_xu_ly,
        don_vi_thuc_hien_xu_ly_rui_ro: value.don_vi_thuc_hien_xu_ly_rui_ro,
        don_vi_thuc_hien_xu_ly_rui_ro__signed:
          checked_lddv === true
            ? value.don_vi_thuc_hien_xu_ly_rui_ro__signed
            : null,
        don_vi_thuc_hien_xu_ly_rui_ro_khac: selectUserList?.cqvp_I,
        pho_giam_doc_phu_trach: value.pho_giam_doc_phu_trach,
        pho_giam_doc_phu_trach__signed:
          checked_pgdpc === true ? value.pho_giam_doc_phu_trach__signed : null,
        bo_phan_quan_ly_rui_ro_cua_don_vi:
          value.bo_phan_quan_ly_rui_ro_cua_don_vi,
        lanh_dao_vimc: selectUserList.lanh_dao_vimc,
        nguoi_dai_dien_phan_von_phu_trach_chung:
          value.nguoi_dai_dien_phan_von_phu_trach_chung,
        nguoi_dai_dien_phan_von_phu_trach_chung__signed: checked_nddpv
          ? value.nguoi_dai_dien_phan_von_phu_trach_chung__signed
          : null,
      },
      other: {
        nhan_dien_rui_ro: ruiRoNhanDien,
        rui_ro_de_xuat: ruiRoNhanDienPropose,
      },
      III_kiem_soat_rui_ro: {
        doi_voi_cac_loai_rui_ro_da_nhan_dien: {
          dong_y: value?.doi_voi_cac_loai_rui_ro_da_nhan_dien__dong_y,
          khong_dong_y:
            value?.doi_voi_cac_loai_rui_ro_da_nhan_dien__khong_dong_y,
          dieu_chinh_bo_sung:
            value?.doi_voi_cac_loai_rui_ro_da_nhan_dien__dieu_chinh_bo_sung,
        },
        doi_voi_danh_gia_muc_rui_ro: {
          dong_y: value.doi_voi_danh_gia_muc_rui_ro__dong_y,
          khong_dong_y: value.doi_voi_danh_gia_muc_rui_ro__khong_dong_y,
          dieu_chinh_bo_sung:
            value.doi_voi_danh_gia_muc_rui_ro__dieu_chinh_bo_sung,
        },
        loai_rui_ro_da_de_xuat_xu_ly: value.loai_rui_ro_da_de_xuat_xu_ly,
        danh_gia_bien_phap_xu_ly_da_de_xuat:
          value.danh_gia_bien_phap_xu_ly_da_de_xuat,
        thoi_gian_hoan_thanh_cong_viec_duoc_giao:
          value.thoi_gian_hoan_thanh_cong_viec_duoc_giao,
        bo_phan_neu_y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc:
          value.bo_phan_neu_y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc,
        y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc:
          value.y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc,
        chuyen_vien_thuc_hien: value.chuyen_vien_thuc_hien,
        lanh_dao_ban: selectUserList?.lanh_dao_ban,
        lanh_dao_ban_nddpv: value?.lanh_dao_ban_nddpv,
        lanh_dao_ban_cqvp: value.lanh_dao_ban_cqvp,
        lanh_dao_ban_signed:
          checked_ld === true ? value?.lanh_dao_ban_signed : null,
        lanh_dao_phong_pcathh: value?.lanh_dao_phong_pcathh,
        lanh_dao_phong_pcathh__signed:
          checked_pcathh === true ? value?.lanh_dao_phong_pcathh__signed : null,
        phong_ban_nddpv: value?.phong_ban_nddpv,
        chuyen_vien_thuc_hien_nddpv: value?.chuyen_vien_thuc_hien_nddpv,
        chuyen_vien_thuc_hien_nddpv_signed:
          checked_cvth === true
            ? value?.chuyen_vien_thuc_hien_nddpv_signed
            : null,
        lanh_dao_ban_nddpv2: value?.lanh_dao_ban_nddpv2,
      },
    }

    riskStore.setQueryParams('ruiRoNhanDienList', ruiRoNhanDien)

    if (riskDetail?.tiers) {
      if (riskDetail?.tiers[0]?.actions[0]) {
        await updateAction(riskDetail?.tiers[0]?.actions[0]?.code, {
          data: JSON.stringify(submitData),
          risk_tier_code: riskDetail?.tiers[0]?.code,
          risk_type: type,
        })
      } else {
        await createAction({
          ...submitData,
          data: JSON.stringify(submitData),
          risk_tier_code: riskDetail?.tiers[0]?.code,
        })
      }
      message.success('Lưu thành công')
      handleCancelModal()
      getRiskDetail()
    } else {
      if (dataActions.tier1[0]) {
        dataActions.tier1[0] = submitData
        setDataActions({ ...dataActions, tier1: [...dataActions?.tier1] })
      } else {
        setDataActions({
          ...dataActions,
          tier1: [submitData],
        })
      }
      message.success('Lưu thành công')
      handleCancelModal()
    }
  }
  const valueStyle = { marginLeft: 8 }

  const [radioValueL, setRadioValueL] = useState()
  const [radioValueDG, setRadioValueDG] = useState()
  const [radioValueTG, setRadioValueTG] = useState()

  useEffect(() => {
    setRadioValueL(
      dataActions?.tier1[0]?.III_kiem_soat_rui_ro?.loai_rui_ro_da_de_xuat_xu_ly
    )
    setRadioValueDG(
      dataActions?.tier1[0]?.III_kiem_soat_rui_ro
        ?.danh_gia_bien_phap_xu_ly_da_de_xuat
    )
    setRadioValueTG(
      dataActions?.tier1[0]?.III_kiem_soat_rui_ro
        ?.thoi_gian_hoan_thanh_cong_viec_duoc_giao
    )
  }, [dataActions])
  const onChangeL = e => {
    setRadioValueL(e.target.value)
  }
  const onChangeDG = e => {
    setRadioValueDG(e.target.value)
  }
  const onChangeTG = e => {
    setRadioValueTG(e.target.value)
  }
  const {
    isOpen: isVisibleSelectUserPopup,
    handleOpen: handleVisibleSelectUserPopup,
    handleClose: handleCloseSelectUserPopup,
  } = useOpenItem()
  const handleRadioChange = e => {
    setSelectedOption(e.target.value)
    setType(() => renderOptionToType(e.target.value))
  }
  const handleRadioChangeV2 = e => {
    setSelectedCqvp(e.target.value)
  }
  const renderTypeToOption = type => {
    if (type === 'NDDPV') return 'nddpv_cua_vimc_tai_dntv'
    if (type === 'DVPT') return 'dvpt_cua_vimc'
    if (type === 'CQVP') return 'cac_don_vi_thuoc_cq_vp_cua_vimc'
  }
  const renderOptionToType = type => {
    if (type === 'nddpv_cua_vimc_tai_dntv') return 'NDDPV'
    if (type === 'dvpt_cua_vimc') return 'DVPT'
    if (type === 'cac_don_vi_thuoc_cq_vp_cua_vimc') return 'CQVP'
  }
  // useEffect(() => {
  //   ;(async () => {
  //     console.log('change company')
  //     try {
  //       if (selectedOption === 'cac_don_vi_thuoc_cq_vp_cua_vimc') {
  //         await departmentStore.getDepartmentList()
  //       }
  //     } catch (err) {
  //       console.log(err)
  //       message.error(err.vi || 'Đã có lỗi xảy ra!')
  //     }
  //   })()
  //   // return () => {
  //   // riskStore.clearRiskStore()
  //   // actionStore.clearActionStore()
  //   // }
  // }, [selectedOption])
  const [checked_ptc, setChecked_ptc] = useState(false)
  const [checked_nth, setChecked_nth] = useState(false)
  const [checked_ldp_dvpt, setChecked_ldp_dvpt] = useState(false)
  const [checked_pcathh, setChecked_pcathh] = useState(false)
  const [checked_lddv, setChecked_lddv] = useState(false)
  const [checked_nddpv, setChecked_nddpv] = useState(false)
  const [checked_pgdpc, setChecked_pgdpc] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [disabled_ptc, setDisabled_ptc] = useState(true)
  const [disabled_nth, setDisabled_nth] = useState(true)
  const [disabled_nddpv, setDisabled_nddpv] = useState(true)
  const [disabled_pgdpc, setDisabled_pgdpc] = useState(true)
  const [disabled_dvpt, setDisabled_dvpt] = useState(true)
  const [disabled_pacthh, setDisabled_pacthh] = useState(true)
  const toggleDisable = e => {
    if (e?.length > 0) {
      setDisabled(false)
    }
    if (e?.length < 1) {
      setChecked_lddv(false)
      setDisabled(true)
    }
  }

  const toggleDissablePcathh = e => {
    if (e?.length > 0) {
      setDisabled_pacthh(false)
    }
    if (e?.length < 1) {
      setChecked_pcathh(false)
      setDisabled_pacthh(true)
    }
  }
  const onChangeCheckedPtc = e => {
    setChecked_ptc(e.target.checked)
  }

  const onChangeCheckedPcathh = e => {
    setChecked_pcathh(e.target.checked)
  }

  const onChangeCheckedLddv = e => {
    setChecked_lddv(e.target.checked)
  }

  const toggleDisablePgdpc = e => {
    if (e?.length > 0) {
      setDisabled_pgdpc(false)
    }
    if (e?.length < 1) {
      setChecked_pgdpc(false)
      setDisabled_pgdpc(true)
    }
  }

  const onChangeCheckedPgdpc = e => {
    setChecked_pgdpc(e.target.checked)
  }

  const toggleDisablePtc = e => {
    if (e?.length > 0) {
      setDisabled_ptc(false)
    }
    if (e?.length < 1) {
      setChecked_ptc(false)
      setDisabled_ptc(true)
    }
  }
  const toggleDisableDvpt = e => {
    if (e?.length > 0) {
      setDisabled_dvpt(false)
    }
    if (e?.length < 1) {
      setChecked_ldp_dvpt(false)
      setDisabled_dvpt(true)
    }
  }
  const onChangeCheckedDvpt = e => {
    setChecked_ldp_dvpt(e.target.checked)
  }

  const toggleDisableNth = e => {
    if (e?.length > 0) {
      setDisabled_nth(false)
    }
    if (e?.length < 1) {
      setChecked_nth(false)
      setDisabled_nth(true)
    }
  }
  const toggleDisableNthk = value => {
    if (value.target.value.trim() !== '') {
      setDisabled_nth(false)
    } else {
      setChecked_nth(false)
      setDisabled_nth(true)
    }
  }
  const onChangeCheckedNth = e => {
    setChecked_nth(e.target.checked)
  }

  const onChangeCheckedLdpDvpt = e => {
    setChecked_ldp_dvpt(e.target.checked)
  }

  const toggleDisableNddpv = e => {
    if (e?.length > 0) {
      setDisabled_nddpv(false)
    }
    if (e?.length < 1) {
      setChecked_nddpv(false)
      setDisabled_nddpv(true)
    }
  }
  const onChangeCheckedNddpv = e => {
    setChecked_nddpv(e.target.checked)
  }

  const toggleDisableBpql = e => {
    if (e?.length > 0) {
      setDisabled_nddpv(false)
    }
    if (e?.length < 1) {
      setChecked_nddpv(false)
      setDisabled_nddpv(true)
    }
  }
  useEffect(() => {
    if (
      (selectUserList.lanh_dao_vimc.length > 0 && type === 'CQVP') ||
      (selectUserList.lanh_dao_vimc.length > 0 && type === 'DVPT')
    ) {
      setDisabled_nddpv(false)
    } else if (
      (selectUserList.lanh_dao_vimc.length == 0 && type === 'CQVP') ||
      (selectUserList.lanh_dao_vimc.length == 0 && type === 'DVPT')
    ) {
      setChecked_nddpv(false)
      setDisabled_nddpv(true)
    }
  }, [selectUserList.lanh_dao_vimc.length])
  useEffect(() => {
    if (
      dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
        ?.nguoi_thuc_hien_signed === '(Đã ký)'
    ) {
      setChecked_nth(true)
    }
    if (
      dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
        ?.nddpv_phu_trach_chung__signed === '(Đã ký)'
    ) {
      setChecked_ptc(true)
    }
    if (
      dataActions?.tier1[0]?.II_xu_ly_rui_ro
        ?.don_vi_thuc_hien_xu_ly_rui_ro__signed === '(Đã ký)'
    ) {
      setChecked_lddv(true)
    }
    if (
      dataActions?.tier1[0]?.II_xu_ly_rui_ro?.pho_giam_doc_phu_trach__signed ===
      '(Đã ký)'
    ) {
      setChecked_pgdpc(true)
    }
    if (
      dataActions?.tier1[0]?.II_xu_ly_rui_ro
        ?.nguoi_dai_dien_phan_von_phu_trach_chung__signed === '(Đã ký)'
    ) {
      setChecked_nddpv(true)
    }
    if (
      dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
        ?.lanh_dao_phong_dvpt__signed === '(Đã ký)'
    ) {
      setChecked_ldp_dvpt(true)
    }
    if (
      dataActions?.tier1[0]?.III_kiem_soat_rui_ro
        ?.lanh_dao_phong_pcathh__signed === '(Đã ký)'
    ) {
      setChecked_pcathh(true)
    }
  }, [])
  useEffect(() => {
    if (
      dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
        ?.nguoi_thuc_hien_sign?.length > 0 ||
      dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
        ?.nguoi_thuc_hien_sign_khac !== undefined
    ) {
      setDisabled_nth(false)
    }
    if (
      dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
        ?.lanh_dao_phong_dvpt?.length > 0 ||
      dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
        ?.lanh_dao_phong_dvpt !== undefined
    ) {
      setDisabled_dvpt(false)
    }
    if (
      dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
        ?.nddpv_phu_trach_chung?.length > 0 ||
      dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
        ?.lanh_dao_don_vi_phu_thuoc_cqvp?.length > 0 ||
      dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
        ?.lanh_dao_don_vi_phu_thuoc?.length > 0
    ) {
      setDisabled_ptc(false)
    }
    if (
      dataActions?.tier1[0]?.II_xu_ly_rui_ro?.don_vi_thuc_hien_xu_ly_rui_ro
        ?.length > 0
    ) {
      setDisabled(false)
    }
    if (
      dataActions?.tier1[0]?.II_xu_ly_rui_ro
        ?.nguoi_dai_dien_phan_von_phu_trach_chung?.length > 0 ||
      dataActions?.tier1[0]?.II_xu_ly_rui_ro?.bo_phan_quan_ly_rui_ro_cua_don_vi
        ?.length > 0
    ) {
      setDisabled_nddpv(false)
    }
    if (
      dataActions?.tier1[0]?.II_xu_ly_rui_ro?.pho_giam_doc_phu_trach?.length > 0
    ) {
      setDisabled_pgdpc(false)
    }
  }, [])

  const [checked_cvth, setChecked_cvth] = useState(false)
  const [checked_ld, setChecked_ld] = useState(false)

  const [disabled_cvth, setDisabled_cvth] = useState(true)
  const [disabled_ld, setDisabled_ld] = useState(true)

  const handldeSetValue = value => {
    const listDataNew = treeData.find(e => e.title === value)
    if (listDataNew) setListChildren(listDataNew.children)
    if (
      value !== dataActions?.tier1[0]?.III_kiem_soat_rui_ro?.phong_ban_nddpv ||
      value === undefined
    ) {
      form.setFieldsValue({
        chuyen_vien_thuc_hien_nddpv: undefined,
        lanh_dao_ban_nddpv2: undefined,
      })
    }
    // if (value === undefined) {
    //   setDisabled_cvth(true)
    //   setChecked_cvth(false)
    //   setDisabled_ld(true)
    //   setChecked_ld(false)
    // }
  }
  const toggleDisableCvth = e => {
    if (e?.length > 0) {
      setDisabled_cvth(false)
    }
    if (e?.length < 1) {
      setChecked_cvth(false)
      setDisabled_cvth(true)
    }
  }
  const toggleDisableCvthk = value => {
    if (value.target.value.trim() === '') {
      setDisabled_cvth(true)
      setChecked_cvth(false)
    } else {
      setDisabled_cvth(false)
    }
  }
  const onChangeCheckedCvth = e => {
    setChecked_cvth(e.target.checked)
  }

  const toggleDisableLd = e => {
    if (e?.length > 0) {
      setDisabled_ld(false)
    }
    if (e?.length < 1) {
      setChecked_ld(false)
      setDisabled_ld(true)
    }
  }
  useEffect(() => {
    if (selectUserList.lanh_dao_ban.length > 0 && type === 'CQVP') {
      setDisabled_ld(false)
    } else if (selectUserList.lanh_dao_ban.length == 0 && type === 'CQVP') {
      setDisabled_ld(true)
      setChecked_ld(false)
    }
  }, [selectUserList.lanh_dao_ban.length])

  const onChangeCheckedLd = e => {
    setChecked_ld(e.target.checked)
  }

  useEffect(() => {
    if (
      dataActions?.tier1[0]?.III_kiem_soat_rui_ro
        ?.chuyen_vien_thuc_hien_nddpv_signed === '(Đã ký)'
    ) {
      setChecked_cvth(true)
    }
    if (
      dataActions?.tier1[0]?.III_kiem_soat_rui_ro?.lanh_dao_ban_signed ===
      '(Đã ký)'
    ) {
      setChecked_ld(true)
    }
  }, [dataActions])
  useEffect(() => {
    if (
      dataActions?.tier1[0]?.III_kiem_soat_rui_ro?.chuyen_vien_thuc_hien_nddpv
        ?.length > 0
    ) {
      setDisabled_cvth(false)
    }
    if (
      dataActions?.tier1[0]?.III_kiem_soat_rui_ro?.chuyen_vien_thuc_hien
        ?.length > 0
    ) {
      setDisabled_cvth(false)
    }
    if (
      dataActions?.tier1[0]?.III_kiem_soat_rui_ro?.lanh_dao_ban_nddpv2?.length >
      0
    ) {
      setDisabled_ld(false)
    }
    if (
      dataActions?.tier1[0]?.III_kiem_soat_rui_ro?.lanh_dao_ban_cqvp?.length > 0
    ) {
      setDisabled_ld(false)
    }
    if (
      dataActions?.tier1[0]?.III_kiem_soat_rui_ro?.lanh_dao_phong_pcathh
        ?.length > 0
    ) {
      setDisabled_pacthh(false)
    }
  }, [dataActions])

  const handleSelectUser = assigneeType => {
    handleVisibleSelectUserPopup()
    handleOpenSelectUserDepartmentPopup(assigneeType)
  }

  return (
    <>
      <Modal
        width={800}
        destroyOnClose={true}
        afterClose={() => {
          setIsRenderRichText(false)
        }}
        zIndex={500}
        style={{ top: 40 }}
        title={'A. Tuyến phòng vệ thứ nhất'}
        visible={isVisible}
        maskClosable={false}
        // transitionName=""
        // maskTransitionName=""
        onCancel={() => {
          handleCancelModal()
        }}
        footer={null}>
        <Form
          form={form}
          layout={'vertical'}
          onFinish={handleCreateRiskId}
          preserve={false}>
          <h3>Đối tượng thực hiện</h3>
          <Form.Item name="group_doi_tuong">
            <Radio.Group onChange={handleRadioChange}>
              {type === 'NDDPV' ? (
                <>
                  <Form.Item value="nddpv_cua_vimc_tai_dntv">
                    <label style={{ marginRight: '20px' }}>
                      NĐDPV của {systemName} tại DNTV (trong trường hợp giao
                      NĐDPV thực hiện công việc):
                    </label>
                  </Form.Item>
                  <Form.Item
                    name="department_code_nddpv_cua_vimc_tai_dntv"
                    style={{ width: '170%', marginBottom: '10px' }}>
                    {selectedOption === 'nddpv_cua_vimc_tai_dntv' && (
                      <Select
                        showSearch
                        allowClear // Cho phép xóa lựa chọn đã chọn
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        // Hiện tại không rõ tại sao có setDepartmentCodeNDDPV nên tạm thời cmt
                        // onChange={value => {
                        //   setDepartmentCodeNDDPV(value)
                        // }}
                        placeholder={`Chọn NĐDPV của ${systemName} tại DNTV`}>
                        {Array.isArray(riskUnitsV2) &&
                          riskUnitsV2.map((item, index) => (
                            <Select.Option key={index} value={item.code}>
                              {item.name}
                            </Select.Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </>
              ) : type === 'DVPT' ? (
                <>
                  <Form.Item value="dvpt_cua_vimc">
                    <label style={{ marginRight: '20px' }}>
                      ĐVPT của {systemName} (trong trường hợp ĐVPT thực hiện
                      công việc):
                    </label>
                  </Form.Item>
                  <Form.Item
                    name="dvpt_cua_vimc"
                    style={{ width: '217%', marginBottom: '10px' }}>
                    {selectedOption === 'dvpt_cua_vimc' && (
                      <Select
                        showSearch
                        allowClear // Cho phép xóa lựa chọn đã chọn
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder={`Chọn ĐVPT của ${systemName}`}>
                        {Array.isArray(riskUnitsV2) &&
                          riskUnitsV2.map((item, index) => (
                            <Select.Option key={item.code} value={item.code}>
                              {item.name}
                            </Select.Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item value="cac_don_vi_thuoc_cq_vp_cua_vimc">
                    <label style={{ marginRight: '20px' }}>
                      Các Đơn vị thuộc cơ quan Văn phòng của {systemName} (trong
                      trường hợp Văn phòng cơ quan/ các Ban CMNV/TT CNTT thực
                      hiện công việc):
                    </label>
                  </Form.Item>
                  <Form.Item
                    name="cac_don_vi_thuoc_cq_vp_cua_vimc"
                    style={{ width: '105%', marginBottom: '10px' }}>
                    {selectedOption === 'cac_don_vi_thuoc_cq_vp_cua_vimc' && (
                      <Select
                        showSearch
                        allowClear // Cho phép xóa lựa chọn đã chọn
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder={`Chọn CQVP của ${systemName}`}>
                        {departmentWithUserMasterList.map((item, index) => (
                          <Select.Option key={index} value={item.code}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </>
              )}
            </Radio.Group>
          </Form.Item>
          <h3>I. NHẬN DIỆN RỦI RO VÀ ĐÁNH GIÁ RỦI RO</h3>
          <h3>1. Công việc/lĩnh vực hoạt động</h3>
          <Form.Item name={'cong_viec_theo'}>
            <Checkbox.Group>
              <Space direction="vertical">
                <Checkbox value={'cong_viec_thuoc_chuc_nang_nhiem_vu'}>
                  a/ Công việc thuộc chức năng, nhiệm vụ
                </Checkbox>
                <Checkbox value={'cong_viec_theo_kpi_kri'}>
                  b/ Công việc theo KPI/KRI
                </Checkbox>
                <Checkbox value={'cong_viec_theo_muc_tieu_chien_luoc'}>
                  c/ Công việc theo mục tiêu, chiến lược
                </Checkbox>
              </Space>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item
            name={'noi_dung_cong_viec_khac_ko_thuoc_cac_nhom_tren'}
            label={'d/ Nội dung công việc khác không thuộc các nhóm trên:'}>
            <Input placeholder={'Nhập nội dung'} />
          </Form.Item>
          <h3>2. Công việc thực hiện</h3>
          <Form.Item
            label={'a/ Nội dung công việc:'}
            name={'noi_dung_cong_viec'}>
            {!isRenderRichText && (
              <div style={{ height: 312, position: 'relative' }}>
                <SpinnerInlineComponent
                  sizeSpin={'small'}
                  tip="Đang tải dữ liệu..."
                  isLoading={!isRenderRichText}
                  backGround={'#ffffffc7'}
                />
              </div>
            )}
            {isRenderRichText && (
              <RichEditor
                EDITOR_REF={CONTENT_REF}
                placeholder={'Nhập nội dung'}
                editorContent={
                  dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                    ?.cong_viec_thuc_hien.noi_dung_cong_viec || ''
                }
              />
            )}
          </Form.Item>
          <Form.Item
            name={'nguoi_thuc_hien__cong_viec'}
            label={'b/ Người thực hiện:'}>
            <Input placeholder={'Nhập nội dung'} />
          </Form.Item>
          <Form.Item
            name={'thoi_gian_hoan_thanh'}
            label={'c/ Thời gian hoàn thành:'}>
            <Input placeholder={'Nhập nội dung'} />
          </Form.Item>
          <h3>
            3. Nhận diện rủi ro và đánh giá rủi ro{' '}
            {type === 'NDDPV' ? (
              ''
            ) : (
              <Button
                style={{ marginTop: 8, marginLeft: 8, color: blue }}
                onClick={() => {
                  setIsVisibleRuiRoNhanDienTemplate(true)
                }}>
                Chọn
              </Button>
            )}
          </h3>
          <Form.Item name={'nhan_dien_rui_ro'} label={'a/ Nhận diện rủi ro:'}>
            {!isRenderRichText && (
              <div style={{ height: 312, position: 'relative' }}>
                <SpinnerInlineComponent
                  sizeSpin={'small'}
                  tip="Đang tải dữ liệu..."
                  isLoading={!isRenderRichText}
                  backGround={'#ffffffc7'}
                />
              </div>
            )}
            {isRenderRichText && (
              <RichEditor
                EDITOR_REF={NDRR_REF}
                placeholder={'Nhập nội dung'}
                editorContent={
                  dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                    ?.nhan_dien_rui_ro_va_danh_gia_rui_ro?.nhan_dien_rui_ro ||
                  ''
                }
              />
            )}
          </Form.Item>
          <Form.Item
            name={'xay_dung_giai_phap_giam_thieu_phong_ngua'}
            label={'b/ Xây dựng giải pháp giảm thiểu, phòng ngừa:'}>
            {!isRenderRichText && (
              <div style={{ height: '312px', position: 'relative' }}>
                <SpinnerInlineComponent
                  sizeSpin={'small'}
                  tip="Đang tải dữ liệu..."
                  isLoading={!isRenderRichText}
                  backGround={'#ffffffc7'}
                />
              </div>
            )}
            {isRenderRichText && (
              <RichEditor
                EDITOR_REF={XDGPGTPN_REF}
                placeholder={'Nhập nội dung'}
                editorContent={
                  dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                    .nhan_dien_rui_ro_va_danh_gia_rui_ro
                    .xay_dung_giai_phap_giam_thieu_phong_ngua || ''
                }
              />
            )}
          </Form.Item>
          <p>c/ Đánh giá rủi ro:</p>
          {typeof dataActions.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
            ?.nhan_dien_rui_ro_va_danh_gia_rui_ro?.p === 'string' ? (
            <RiskSelects>
              <Form.Item name={'p'} label={'P:'} style={{ width: '30%' }}>
                <Select
                // onChange={value => {
                //   setRiskPoint({ ...riskPoint, p: +value })
                // }}
                >
                  <Select.Option value="1">
                    1 - Rất thấp (Hiếm khi xảy ra)
                  </Select.Option>
                  <Select.Option value="2">
                    2 - Thấp (Đôi khi xảy ra)
                  </Select.Option>
                  <Select.Option value="3">
                    3 - Trung bình (Hay xảy ra)
                  </Select.Option>
                  <Select.Option value="4">
                    4 - Cao (Thường xuyên xảy ra)
                  </Select.Option>
                  <Select.Option value="5">
                    5 - Rất cao (Gần như chắc chắn xảy ra)
                  </Select.Option>
                </Select>
              </Form.Item>
              <span style={{ marginTop: 16 }}>X</span>
              <Form.Item name={'s'} label={'S:'} style={{ width: '30%' }}>
                <Select
                // onChange={value => {
                //   setRiskPoint({ ...riskPoint, s: +value })
                // }}
                >
                  <Select.Option value="1">
                    1 - Không đáng kể (Ảnh hưởng thấp)
                  </Select.Option>
                  <Select.Option value="2">
                    2 - Vừa phải (Ảnh hưởng trung bình)
                  </Select.Option>
                  <Select.Option value="3">
                    3 - Quan trọng (Ảnh hưởng cao)
                  </Select.Option>
                  <Select.Option value="4">
                    4 - Trầm trọng (Ảnh hưởng nghiêm trọng)
                  </Select.Option>
                  <Select.Option value="5">
                    5 - Rất trầm trọng (Ảnh hưởng đặc biệt nghiêm trọng)
                  </Select.Option>
                </Select>
              </Form.Item>
              <span style={{ marginTop: 16 }}>=</span>
              <Form.Item name={'r'} label={'R:'} style={{ width: '30%' }}>
                <Select>
                  <Select.Option value="1">
                    1 -{'>'} 5 - Nguy cơ rủi ro thấp (Được phép thực hiện)
                  </Select.Option>
                  <Select.Option value="2">
                    6 -{'>'} 15 - Nguy cơ rủi ro trung bình (Hoạt động chỉ được
                    phép tiến hành với sự quản lý, kiểm soát thích hợp)
                  </Select.Option>
                  <Select.Option value="3">
                    16 -{'>'} 25 - Nguy cơ rủi ro cao (Hoạt động không được phép
                    tiến hành. Cần phải thiết lập các biện pháp kiểm soát chặt
                    chẽ hơn để giảm thiểu rủi ro)
                  </Select.Option>
                </Select>
              </Form.Item>
            </RiskSelects>
          ) : type === 'NDDPV' ? (
            // với type = NDDPV thì mục c/ Đánh giá rủi ro vẫn như cũ
            <RiskSelects>
              <Form.Item name={`p[${0}]`} label={'P:'} style={{ width: '30%' }}>
                <Select
                  onChange={value => {
                    setRiskPoint(prevState => {
                      const newRiskPoint = { ...prevState }
                      newRiskPoint.p[0] = { id: null, value: +value }
                      return newRiskPoint
                    })
                  }}>
                  <Select.Option value="1">
                    1 - Rất thấp (Hiếm khi xảy ra)
                  </Select.Option>
                  <Select.Option value="2">
                    2 - Thấp (Đôi khi xảy ra)
                  </Select.Option>
                  <Select.Option value="3">
                    3 - Trung bình (Hay xảy ra)
                  </Select.Option>
                  <Select.Option value="4">
                    4 - Cao (Thường xuyên xảy ra)
                  </Select.Option>
                  <Select.Option value="5">
                    5 - Rất cao (Gần như chắc chắn xảy ra)
                  </Select.Option>
                </Select>
              </Form.Item>
              <span style={{ marginTop: 16 }}>X</span>
              <Form.Item name={`s[${0}]`} label={'S:'} style={{ width: '30%' }}>
                <Select
                  onChange={value => {
                    setRiskPoint(prevState => {
                      const newRiskPoint = { ...prevState }
                      newRiskPoint.s[0] = { id: null, value: +value }
                      return newRiskPoint
                    })
                  }}>
                  <Select.Option value="1">
                    1 - Không đáng kể (Ảnh hưởng thấp)
                  </Select.Option>
                  <Select.Option value="2">
                    2 - Vừa phải (Ảnh hưởng trung bình)
                  </Select.Option>
                  <Select.Option value="3">
                    3 - Quan trọng (Ảnh hưởng cao)
                  </Select.Option>
                  <Select.Option value="4">
                    4 - Trầm trọng (Ảnh hưởng nghiêm trọng)
                  </Select.Option>
                  <Select.Option value="5">
                    5 - Rất trầm trọng (Ảnh hưởng đặc biệt nghiêm trọng)
                  </Select.Option>
                </Select>
              </Form.Item>
              <span style={{ marginTop: 16 }}>=</span>
              <Form.Item name={`r[${0}]`} label={'R:'} style={{ width: '30%' }}>
                <Select>
                  <Select.Option value="1">
                    1 -{'>'} 5 - Nguy cơ rủi ro thấp (Được phép thực hiện)
                  </Select.Option>
                  <Select.Option value="2">
                    6 -{'>'} 15 - Nguy cơ rủi ro trung bình (Hoạt động chỉ được
                    phép tiến hành với sự quản lý, kiểm soát thích hợp)
                  </Select.Option>
                  <Select.Option value="3">
                    16 -{'>'} 25 - Nguy cơ rủi ro cao (Hoạt động không được phép
                    tiến hành. Cần phải thiết lập các biện pháp kiểm soát chặt
                    chẽ hơn để giảm thiểu rủi ro)
                  </Select.Option>
                </Select>
              </Form.Item>
            </RiskSelects>
          ) : (
            ruiRoNhanDien?.map((item, index) => (
              <div key={index}>
                <div>{item.rui_ro_nhan_dien || item.identification_risk}</div>
                <RiskSelects>
                  <Form.Item
                    name={`p[${index}]`}
                    label={'P:'}
                    style={{ width: '30%' }}>
                    <Select
                      onChange={value => {
                        setRiskPoint(prevState => {
                          const newRiskPoint = { ...prevState }
                          newRiskPoint.p[index] = { id: item.id, value: +value }
                          return newRiskPoint
                        })
                      }}>
                      <Select.Option value="1">
                        1 - Rất thấp (Hiếm khi xảy ra)
                      </Select.Option>
                      <Select.Option value="2">
                        2 - Thấp (Đôi khi xảy ra)
                      </Select.Option>
                      <Select.Option value="3">
                        3 - Trung bình (Hay xảy ra)
                      </Select.Option>
                      <Select.Option value="4">
                        4 - Cao (Thường xuyên xảy ra)
                      </Select.Option>
                      <Select.Option value="5">
                        5 - Rất cao (Gần như chắc chắn xảy ra)
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <span style={{ marginTop: 16 }}>X</span>
                  <Form.Item
                    name={`s[${index}]`}
                    label={'S:'}
                    style={{ width: '30%' }}>
                    <Select
                      onChange={value => {
                        setRiskPoint(prevState => {
                          const newRiskPoint = { ...prevState }
                          newRiskPoint.s[index] = { id: item.id, value: +value }
                          return newRiskPoint
                        })
                      }}>
                      <Select.Option value="1">
                        1 - Không đáng kể (Ảnh hưởng thấp)
                      </Select.Option>
                      <Select.Option value="2">
                        2 - Vừa phải (Ảnh hưởng trung bình)
                      </Select.Option>
                      <Select.Option value="3">
                        3 - Quan trọng (Ảnh hưởng cao)
                      </Select.Option>
                      <Select.Option value="4">
                        4 - Trầm trọng (Ảnh hưởng nghiêm trọng)
                      </Select.Option>
                      <Select.Option value="5">
                        5 - Rất trầm trọng (Ảnh hưởng đặc biệt nghiêm trọng)
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <span style={{ marginTop: 16 }}>=</span>
                  <Form.Item
                    name={`r[${index}]`}
                    label={'R:'}
                    style={{ width: '30%' }}>
                    <Select>
                      <Select.Option value="1">
                        1 -{'>'} 5 - Nguy cơ rủi ro thấp (Được phép thực hiện)
                      </Select.Option>
                      <Select.Option value="2">
                        6 -{'>'} 15 - Nguy cơ rủi ro trung bình (Hoạt động chỉ
                        được phép tiến hành với sự quản lý, kiểm soát thích hợp)
                      </Select.Option>
                      <Select.Option value="3">
                        16 -{'>'} 25 - Nguy cơ rủi ro cao (Hoạt động không được
                        phép tiến hành. Cần phải thiết lập các biện pháp kiểm
                        soát chặt chẽ hơn để giảm thiểu rủi ro)
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </RiskSelects>
              </div>
            ))
          )}
          <Divider />
          <p>Người thực hiện:</p>{' '}
          {type === 'NDDPV' ? (
            <>
              <Form.Item name={'nguoi_thuc_hien__signed'}>
                <Space direction="vertical">
                  <Checkbox
                    checked={checked_nth}
                    disabled={disabled_nth}
                    onChange={e => onChangeCheckedNth(e)}
                    value={'(Đã ký)'}>
                    Xác nhận ký
                  </Checkbox>
                </Space>
              </Form.Item>
              <Form.Item name={'nguoi_thuc_hien__sign'}>
                <TreeSelect
                  treeData={treeData_BP}
                  style={{ width: '100%' }}
                  showArrow
                  allowClear
                  showSearch
                  filterTreeNode
                  treeNodeFilterProp={'title'}
                  treeCheckable={true}
                  showCheckedStrategy={SHOW_ALL}
                  treeCheckStrictly={true}
                  onChange={e => toggleDisableNth(e)}
                  placeholder={'Chọn người thực hiện'}
                />
              </Form.Item>
              <Form.Item name={'nguoi_thuc_hien__sign_khac'}>
                <Input
                  onInput={value => toggleDisableNthk(value)}
                  placeholder={'Nhập người thực hiện khác danh sách'}
                />
              </Form.Item>
            </>
          ) : type === 'DVPT' ? (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <Form.Item name={'nguoi_thuc_hien__signed'}>
                  <Space direction="vertical">
                    <Checkbox
                      checked={checked_nth}
                      disabled={disabled_nth}
                      onChange={e => onChangeCheckedNth(e)}
                      value={'(Đã ký)'}>
                      Xác nhận ký
                    </Checkbox>
                  </Space>
                </Form.Item>
                <Form.Item name={'nguoi_thuc_hien__sign'}>
                  <TreeSelect
                    treeData={treeData}
                    style={{ width: '100%' }}
                    showArrow
                    allowClear
                    showSearch
                    filterTreeNode
                    treeNodeFilterProp={'title'}
                    treeCheckable={true}
                    showCheckedStrategy={SHOW_ALL}
                    treeCheckStrictly={true}
                    onChange={e => toggleDisableNth(e)}
                    placeholder={'Chọn người thực hiện'}
                  />
                </Form.Item>
              </div>
              <Form.Item name={'nguoi_thuc_hien_dvpt_y_kien'} label={'Ý kiến:'}>
                <Input placeholder={'Nhập nội dung'} />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item name={'nguoi_thuc_hien__signed'}>
                <Space direction="vertical">
                  <Checkbox
                    checked={checked_nth}
                    disabled={disabled_nth}
                    onChange={e => onChangeCheckedNth(e)}
                    value={'(Đã ký)'}>
                    Xác nhận ký
                  </Checkbox>
                </Space>
              </Form.Item>
              <Form.Item name={'nguoi_thuc_hien__sign_khac'}>
                <Input
                  onInput={value => toggleDisableNthk(value)}
                  placeholder={'Nhập người thực hiện khác danh sách'}
                />
              </Form.Item>
            </>
          )}
          {type === 'NDDPV' ? (
            <p>NĐDPV phụ trách chung:</p>
          ) : type === 'DVPT' ? (
            <>
              <p>Lãnh đạo Phòng:</p>
              <Form.Item name={'lanh_dao_phong_dvpt__signed'}>
                <Space direction="vertical">
                  <Checkbox
                    checked={checked_ldp_dvpt}
                    disabled={disabled_dvpt}
                    onChange={e => onChangeCheckedLdpDvpt(e)}
                    value={'(Đã ký)'}>
                    Xác nhận ký
                  </Checkbox>
                </Space>
              </Form.Item>
              <Form.Item name={'lanh_dao_phong_dvpt'}>
                <TreeSelect
                  treeData={treeData}
                  style={{ width: '100%' }}
                  showArrow
                  allowClear
                  showSearch
                  filterTreeNode
                  treeNodeFilterProp={'title'}
                  treeCheckable={true}
                  showCheckedStrategy={SHOW_ALL}
                  treeCheckStrictly={true}
                  onChange={e => toggleDisableDvpt(e)}
                  placeholder={'Chọn lãnh đạo phòng'}
                />
              </Form.Item>
              {/* Ý kiến người thực hiện */}

              <Form.Item name={'lanh_dao_dvpt_y_kien'} label={'Ý kiến:'}>
                <Input placeholder={'Nhập nội dung'} />
              </Form.Item>
            </>
          ) : (
            <p>Lãnh đạo Đơn vị/NĐDPV phụ trách chung:</p>
          )}
          {type !== 'DVPT' && (
            <Form.Item name={'nddpv_phu_trach_chung__signed'}>
              <Space direction="vertical">
                <Checkbox
                  checked={checked_ptc}
                  disabled={disabled_ptc}
                  onChange={e => onChangeCheckedPtc(e)}
                  value={'(Đã ký)'}>
                  Xác nhận ký
                </Checkbox>
              </Space>
            </Form.Item>
          )}
          {type === 'NDDPV' ? (
            <Form.Item name={'nddpv_phu_trach_chung'}>
              <TreeSelect
                treeData={treeData}
                style={{ width: '100%' }}
                showArrow
                allowClear
                showSearch
                filterTreeNode
                treeNodeFilterProp={'title'}
                treeCheckable={true}
                showCheckedStrategy={SHOW_ALL}
                treeCheckStrictly={true}
                onChange={e => toggleDisablePtc(e)}
                placeholder={'Chọn NĐDPV phụ trách chung'}
              />
            </Form.Item>
          ) : type === 'DVPT' ? (
            // <Form.Item name={'lanh_dao_don_vi_phu_thuoc'}>
            //   <TreeSelect
            //     treeData={treeData_BP}
            //     style={{ width: '100%' }}
            //     showArrow
            //     allowClear
            //     showSearch
            //     filterTreeNode
            //     treeNodeFilterProp={'title'}
            //     treeCheckable={true}
            //     showCheckedStrategy={SHOW_ALL}
            //     treeCheckStrictly={true}
            //     onChange={e => toggleDisablePtc(e)}
            //     placeholder={'Chọn lãnh đạo đơn vị phụ thuộc'}
            //   />
            // </Form.Item>
            <>
              {/* {selectedList?.length > 0 && (
                <SelectUserRiskItem userList={selectedList} />
              )} */}

              {/* <SelectPeoplePopupLive
                selectedListUpdate={selectedList}
                // onlyOneUser
                // onlySelectOne
                companyList={companyList}
                companyCode={null}
                isVisibleSelectPeoplePopup={isVisibleSelectPeopleExecutorPopup}
                handleSubmitSelectUser={selectedList => {
                  setSelectedList(selectedList)
                  toggleDisablePtc(selectedList)
                  setIsVisibleSelectPeopleExecutorPopup(false)
                }}
                handleCancelSelectUser={() =>
                  setIsVisibleSelectPeopleExecutorPopup(false)
                }
                assigneePopupType={ASSIGNEE_TYPE.lanh_dao_ban}
                getDepartmentWithUsersLive={getDepartmentWithUsersLive}
              /> */}
            </>
          ) : (
            <Form.Item name={'lanh_dao_don_vi_phu_thuoc_cqvp'}>
              <TreeSelect
                treeData={treeData_LD}
                style={{ width: '100%' }}
                showArrow
                allowClear
                showSearch
                filterTreeNode
                treeNodeFilterProp={'title'}
                treeCheckable={true}
                showCheckedStrategy={SHOW_ALL}
                treeCheckStrictly={true}
                onChange={e => toggleDisablePtc(e)}
                placeholder={'Chọn lãnh đạo Đơn vị/NĐDPV phụ trách chung'}
              />
            </Form.Item>
          )}
          {/* Ý kiến lãnh đạo */}
          {/* {type === 'DVPT' ? (
            <Form.Item name={'lanh_dao_dvpt_y_kien'} label={'Ý kiến:'}>
              <Input placeholder={'Nhập nội dung'} />
            </Form.Item>
          ) : null} */}
          {/* <RiskCreateA1
            isRenderRichText={isRenderRichText}
            dataActions={dataActions}
            setIsVisibleRuiRoNhanDienTemplate={
              setIsVisibleRuiRoNhanDienTemplate
            }
            setRiskPoint={setRiskPoint}
            ruiRoNhanDien={ruiRoNhanDien}
            type={type}
            treeData={treeData}
            SHOW_ALL={SHOW_ALL}
            treeData_LD={treeData_LD}
            CONTENT_REF={CONTENT_REF}
            NDRR_REF={NDRR_REF}
            XDGPGTPN_REF={XDGPGTPN_REF}
          /> */}
          <Divider />
          <h3>II. XỬ LÝ RỦI RO</h3>
          <h3>
            1. Đề xuất loại rủi ro cần xử lý{' '}
            {type === 'NDDPV' ? (
              ''
            ) : (
              <Button
                style={{ marginTop: 8, marginLeft: 8, color: blue }}
                onClick={() => {
                  setIsVisibleRuiRoNhanDienTemplates(true)
                }}>
                Chọn
              </Button>
            )}
          </h3>
          <>
            <Form.Item
              name={'loai_rui_ro_de_xuat'}
              label={'a/ Loại rủi ro đề xuất:'}>
              {!isRenderRichText && (
                <div style={{ height: 312, position: 'relative' }}>
                  <SpinnerInlineComponent
                    sizeSpin={'small'}
                    tip="Đang tải dữ liệu..."
                    isLoading={!isRenderRichText}
                    backGround={'#ffffffc7'}
                  />
                </div>
              )}
              {isRenderRichText && (
                <RichEditor
                  EDITOR_REF={LRRDX1_REF}
                  placeholder={'Nhập nội dung'}
                  editorContent={
                    dataActions?.tier1[0]?.II_xu_ly_rui_ro
                      ?.de_xuat_loai_rui_ro_can_xu_ly?.loai_rui_ro_de_xuat || ''
                  }
                />
              )}
            </Form.Item>
            {/* {type === 'NDDPV' && isRenderRichText ? (
              <Form.Item name={'loai_rui_ro_de_xuat_khac'}>
                <RichEditor
                  EDITOR_REF={LRRDX_REF}
                  placeholder={'Nhập loại rủi ro đề xuất khác'}
                  editorContent={
                    dataActions?.tier1[0]?.II_xu_ly_rui_ro
                      .de_xuat_loai_rui_ro_can_xu_ly.loai_rui_ro_de_xuat_khac ||
                    ''
                  }
                />
              </Form.Item>
            ) : (
              ''
            )} */}
          </>
          {type === 'NDDPV' ? (
            <>
              <Form.Item
                name={'bo_phan_de_xuat_khac'}
                label={'b/ Bộ phận đề xuất:'}>
                <Input placeholder="Nhập bộ phận đề xuất"></Input>
              </Form.Item>
            </>
          ) : (
            <>
              {type === 'DVPT' ? (
                <>
                  <Form.Item
                    name={'bo_phan_de_xuat_khac'}
                    label={'b/ Bộ phận đề xuất:'}>
                    <Input placeholder="Nhập bộ phận đề xuất"></Input>
                  </Form.Item>
                </>
              ) : (
                <>
                  {' '}
                  <Form.Item
                    name={'bo_phan_de_xuat'}
                    label={'b/ Bộ phận đề xuất:'}>
                    <Select
                      showSearch
                      allowClear // Cho phép xóa lựa chọn đã chọn
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={'Chọn bộ phận đề xuất'}>
                      {Array.isArray(treeData_BPDX) &&
                        treeData_BPDX.map((item, index) => (
                          <Select.Option key={item.code} value={item.title}>
                            {item.title}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </>
              )}
            </>
          )}
          <Form.Item name={'bien_phap_xu_ly'} label={'c/ Biện pháp xử lý:'}>
            {!isRenderRichText && (
              <div style={{ height: 312, position: 'relative' }}>
                <SpinnerInlineComponent
                  sizeSpin={'small'}
                  tip="Đang tải dữ liệu..."
                  isLoading={!isRenderRichText}
                  backGround={'#ffffffc7'}
                />
              </div>
            )}
            {isRenderRichText && (
              <RichEditor
                EDITOR_REF={BPXL_REF}
                placeholder={'Nhập nội dung'}
                editorContent={
                  dataActions?.tier1[0]?.II_xu_ly_rui_ro
                    .de_xuat_loai_rui_ro_can_xu_ly.bien_phap_xu_ly || ''
                }
              />
            )}
          </Form.Item>
          {type === 'NDDPV' ? (
            <>
              <Form.Item
                name={'nguoi_thuc_hien__de_xuat_khac'}
                label={'d/Người thực hiện:'}>
                <TreeSelect
                  treeData={treeData}
                  style={{ width: '100%' }}
                  showArrow
                  allowClear
                  showSearch
                  filterTreeNode
                  treeNodeFilterProp={'title'}
                  treeCheckable={true}
                  showCheckedStrategy={SHOW_ALL}
                  treeCheckStrictly={true}
                  placeholder={'Chọn người thực hiện'}
                />
              </Form.Item>
              <Form.Item name={'nguoi_thuc_hien__de_xuat'}>
                <Input placeholder={'Nhập người thực hiện khác danh sách'} />
              </Form.Item>
            </>
          ) : (
            <Form.Item
              name={'nguoi_thuc_hien__de_xuat'}
              label={'d/ Người thực hiện:'}>
              <Input placeholder={'Nhập người thực hiện'} />
            </Form.Item>
          )}
          {type == 'NDDPV' ? (
            <h3>2. Lãnh đạo quyết định biện pháp xử lý</h3>
          ) : (
            <>
              {type === 'DVPT' ? (
                <h3>2. Phó giám đốc phụ trách quyết định biện pháp xử lý</h3>
              ) : (
                <h3>2. Lãnh đạo quyết định biện pháp xử lý</h3>
              )}
            </>
          )}
          <Form.Item
            name={'lanh_dao_quyet_dinh_bien_phap_xu_ly'}
            label={'Lãnh đạo:'}
            hidden={true}>
            <Input placeholder={'Nhập nội dung'} />
          </Form.Item>
          {type === 'NDDPV' ? (
            <span>NGƯỜI ĐẠI DIỆN PHẦN VỐN THỰC HIỆN XỬ LÝ RỦI RO:</span>
          ) : (
            <span>LÃNH ĐẠO ĐƠN VỊ THỰC HIỆN XỬ LÝ RỦI RO:</span>
          )}
          <Form.Item name={'don_vi_thuc_hien_xu_ly_rui_ro__signed'}>
            <Space direction="vertical">
              <Checkbox
                checked={checked_lddv ? true : false}
                disabled={disabled}
                onChange={e => onChangeCheckedLddv(e)}
                value={'(Đã ký)'}>
                Xác nhận ký
              </Checkbox>
            </Space>
          </Form.Item>
          <div>
            {type === 'NDDPV' ? (
              <Form.Item name={'don_vi_thuc_hien_xu_ly_rui_ro'}>
                <TreeSelect
                  treeData={treeData}
                  style={{ width: '100%' }}
                  showArrow
                  allowClear
                  showSearch
                  filterTreeNode
                  treeNodeFilterProp={'title'}
                  treeCheckable={true}
                  showCheckedStrategy={SHOW_ALL}
                  treeCheckStrictly={true}
                  onChange={e => toggleDisable(e)}
                  placeholder={
                    'Chọn người đại diện phần vốn thực hiện xử lý rủi ro'
                  }
                />
              </Form.Item>
            ) : (
              <>
                {type === 'DVPT' ? (
                  <Form.Item name={'don_vi_thuc_hien_xu_ly_rui_ro'}>
                    <TreeSelect
                      treeData={treeData}
                      style={{ width: '100%' }}
                      showArrow
                      allowClear
                      showSearch
                      filterTreeNode
                      treeNodeFilterProp={'title'}
                      treeCheckable={true}
                      showCheckedStrategy={SHOW_ALL}
                      treeCheckStrictly={true}
                      onChange={e => toggleDisable(e)}
                      placeholder={'Chọn lãnh đạo phòng'}
                    />
                  </Form.Item>
                ) : (
                  <div>
                    <Form.Item name="don_vi_thuc_hien_xu_ly_rui_ro">
                      <TreeSelect
                        // treeDataSimpleMode
                        treeData={treeData_BPDX}
                        style={{ width: '100%' }}
                        showArrow
                        allowClear
                        showSearch
                        filterTreeNode
                        treeNodeFilterProp={'title'}
                        treeCheckable={true}
                        showCheckedStrategy={SHOW_ALL}
                        treeCheckStrictly={true}
                        onChange={e => toggleDisable(e)}
                        placeholder={
                          'Chọn lãnh đạo đơn vị thực hiện xử lý rủi ro'
                        }
                      />
                    </Form.Item>
                  </div>
                )}
              </>
            )}
          </div>
          {type === 'DVPT' ? (
            <div>
              <div>CHỌN PHÓ GIÁM ĐỐC PHỤ TRÁCH</div>
              <Form.Item name={'pho_giam_doc_phu_trach__signed'}>
                <Space direction="vertical">
                  <Checkbox
                    checked={checked_pgdpc}
                    disabled={disabled_pgdpc}
                    onChange={e => onChangeCheckedPgdpc(e)}
                    value={'(Đã ký)'}>
                    Xác nhận ký
                  </Checkbox>
                </Space>
              </Form.Item>
              <Form.Item name={'pho_giam_doc_phu_trach'}>
                <TreeSelect
                  treeData={treeData}
                  style={{ width: '100%' }}
                  showArrow
                  allowClear
                  showSearch
                  filterTreeNode
                  treeNodeFilterProp={'title'}
                  treeCheckable={true}
                  showCheckedStrategy={SHOW_ALL}
                  treeCheckStrictly={true}
                  onChange={e => toggleDisablePgdpc(e)}
                  placeholder={'Chọn phó giám đốc phụ trách'}
                />
              </Form.Item>
            </div>
          ) : null}
          {type === 'NDDPV' ? (
            <>
              <span>CHỌN NGƯỜI ĐẠI DIỆN PHẦN VỐN PHỤ TRÁCH CHUNG</span>
              <Form.Item
                name={'nguoi_dai_dien_phan_von_phu_trach_chung__signed'}>
                <Space direction="vertical">
                  <Checkbox
                    checked={checked_nddpv}
                    disabled={disabled_nddpv}
                    onChange={e => onChangeCheckedNddpv(e)}
                    value={'(Đã ký)'}>
                    Xác nhận ký
                  </Checkbox>
                </Space>
              </Form.Item>
              <Form.Item name={'nguoi_dai_dien_phan_von_phu_trach_chung'}>
                <TreeSelect
                  treeData={treeData_BP}
                  style={{ width: '100%' }}
                  showArrow
                  allowClear
                  showSearch
                  filterTreeNode
                  treeNodeFilterProp={'title'}
                  treeCheckable={true}
                  showCheckedStrategy={SHOW_ALL}
                  treeCheckStrictly={true}
                  onChange={e => toggleDisableNddpv(e)}
                  placeholder={'Chọn người đại diện phần vốn phụ trách chung'}
                />
              </Form.Item>
            </>
          ) : type === 'DVPT' ? (
            <></>
          ) : (
            <div style={{ marginTop: 16 }}>
              <span>
                LÃNH ĐẠO VIMC (theo phân công trách nhiệm quản lý rủi ro):
              </span>
              <Form.Item
                name={'nguoi_dai_dien_phan_von_phu_trach_chung__signed'}>
                <Space direction="vertical">
                  <Checkbox
                    checked={checked_nddpv}
                    disabled={disabled_nddpv}
                    onChange={e => onChangeCheckedNddpv(e)}
                    value={'(Đã ký)'}>
                    Xác nhận ký
                  </Checkbox>
                </Space>
              </Form.Item>
              <SelectPeopleWrapper>
                <SelectUserButton
                  tooltipTitle="Chọn người"
                  style={{ marginTop: 16 }}
                  // title="LÃNH ĐẠO VIMC (theo phân công trách nhiệm quản lý rủi ro):"
                  handleOnClick={() => {
                    handleSelectUser(ASSIGNEE_TYPE.lanh_dao_vimc)
                    // handleVisibleSelectOneUserPopup()
                    // handleOpenSelectUserDepartmentPopup(
                    //   ASSIGNEE_TYPE.lanh_dao_vimc
                    // )
                  }}
                />
                <SelectListComponentRisk
                  userList={selectUserList.lanh_dao_vimc}
                />
              </SelectPeopleWrapper>
            </div>
          )}
          {type === 'DVPT' && (
            <>
              <Divider />
              <h3>III. Kiểm soát rủi ro</h3>
              <p>
                1. Đối với các loại rủi ro đã nhận diện:
                <span style={valueStyle}>
                  <Space direction="horizontal">
                    <Form.Item
                      name={'doi_voi_cac_loai_rui_ro_da_nhan_dien__dong_y'}>
                      <Radio.Group>
                        <Radio value={'Đồng ý'}>Đồng ý</Radio>
                        <Radio value={'Không đồng ý'}>Không đồng ý</Radio>
                      </Radio.Group>
                    </Form.Item>

                    <Form.Item
                      name={
                        'doi_voi_cac_loai_rui_ro_da_nhan_dien__dieu_chinh_bo_sung'
                      }>
                      <Input placeholder={'Điều chỉnh bổ sung'} />
                    </Form.Item>
                  </Space>
                </span>
              </p>
              <p>
                2. Đối với đánh giá mức rủi ro:
                <span style={valueStyle}>
                  <Space direction="horizontal">
                    <Form.Item name={'doi_voi_danh_gia_muc_rui_ro__dong_y'}>
                      <Radio.Group>
                        <Radio value={'Đồng ý'}>Đồng ý</Radio>
                        <Radio value={'Không đồng ý'}>Không đồng ý</Radio>
                      </Radio.Group>
                    </Form.Item>

                    <Form.Item
                      name={'doi_voi_danh_gia_muc_rui_ro__dieu_chinh_bo_sung'}>
                      <Input placeholder={'Điều chỉnh bổ sung'} />
                    </Form.Item>
                  </Space>
                </span>
              </p>
              <p>
                3. Loại rủi ro đã đề xuất xử lý:
                <span style={valueStyle}>
                  <Space direction="horizontal">
                    <Form.Item name={'loai_rui_ro_da_de_xuat_xu_ly'}>
                      <Radio.Group value={radioValueL} onChange={onChangeL}>
                        <Radio value={'Đồng ý'}>Đồng ý</Radio>
                        <Radio value={'Không đồng ý'}>Không đồng ý</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Space>
                </span>
              </p>
              <p>
                4. Đánh giá biện pháp xử lý đã đề xuất:
                <span style={valueStyle}>
                  <Space>
                    <Form.Item name={'danh_gia_bien_phap_xu_ly_da_de_xuat'}>
                      <Radio.Group value={radioValueDG} onChange={onChangeDG}>
                        <Radio value={'Tuân thủ'}>Tuân thủ</Radio>
                        <Radio value={'Không tuân thủ'}>Không tuân thủ</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Space>
                </span>
              </p>
              <p>
                5. Thời gian hoàn thành công việc được giao:
                <span style={valueStyle}>
                  <Space>
                    <Form.Item
                      name={'thoi_gian_hoan_thanh_cong_viec_duoc_giao'}>
                      <Radio.Group value={radioValueTG} onChange={onChangeTG}>
                        <Radio value={'Đúng thời hạn'}>Đúng thời hạn</Radio>
                        <Radio value={'Không đúng thời hạn'}>
                          Không đúng thời hạn
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Space>
                </span>
              </p>
              <Form.Item
                name={'bo_phan_neu_y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc'}
                label={`6. Ý kiến khác của Ban CMNV ${systemName}:`}>
                <Select
                  showSearch
                  allowClear // Cho phép xóa lựa chọn đã chọn
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder={'Chọn đơn vị'}>
                  {Array.isArray(treeData) &&
                    treeData.map((item, index) => (
                      <Select.Option key={index} value={item.title}>
                        {item.title}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item name={'y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc'}>
                <Input placeholder={'Nhập nội dung'} />
              </Form.Item>
              <Form.Item
                name={'lanh_dao_quyet_dinh_bien_phap_xu_ly'}
                label={'Lãnh đạo:'}
                hidden={true}>
                <Input placeholder={'Nhập nội dung'} />
              </Form.Item>
              <Divider />

              {type === 'NDDPV' ? (
                <></>
              ) : (
                <>
                  <h3>
                    {/* BAN PC&QTRR {systemName}/ BAN CMNV {systemName} */}
                  </h3>
                  <p>LÃNH ĐẠO PHÒNG PCATHH:</p>
                  <Form.Item name={'lanh_dao_phong_pcathh__signed'}>
                    <Space direction="vertical">
                      <Checkbox
                        checked={checked_pcathh}
                        disabled={disabled_pacthh}
                        onChange={e => onChangeCheckedPcathh(e)}
                        value={'(Đã ký)'}>
                        Xác nhận ký
                      </Checkbox>
                    </Space>
                  </Form.Item>
                  <Form.Item name={'lanh_dao_phong_pcathh'}>
                    <TreeSelect
                      treeData={treeData}
                      style={{ width: '100%' }}
                      showArrow
                      allowClear
                      showSearch
                      filterTreeNode
                      treeNodeFilterProp={'title'}
                      treeCheckable={true}
                      showCheckedStrategy={SHOW_ALL}
                      treeCheckStrictly={true}
                      onChange={e => toggleDissablePcathh(e)}
                      placeholder={'Chọn lãnh đạo phòng pacthh'}
                    />
                  </Form.Item>
                  {type === 'DVPT' ? (
                    <>
                      <p>GIÁM ĐỐC:</p>
                      <Form.Item name={'lanh_dao_ban_signed'}>
                        <Space direction="vertical">
                          <Checkbox
                            checked={checked_ld}
                            disabled={disabled_ld}
                            onChange={e => onChangeCheckedLd(e)}
                            value={'(Đã ký)'}>
                            Xác nhận ký
                          </Checkbox>
                        </Space>
                      </Form.Item>
                      <Form.Item name={'lanh_dao_ban_cqvp'}>
                        <TreeSelect
                          treeData={treeData}
                          style={{ width: '100%' }}
                          showArrow
                          allowClear
                          showSearch
                          filterTreeNode
                          treeNodeFilterProp={'title'}
                          treeCheckable={true}
                          showCheckedStrategy={SHOW_ALL}
                          treeCheckStrictly={true}
                          onChange={e => toggleDisableLd(e)}
                          placeholder={'Chọn giám đốc'}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          )}
          <FormActionFooter style={{ marginBottom: 0, marginRight: 0 }}>
            <Button icon={<CloseOutlined />} danger onClick={handleCancelModal}>
              Đóng
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              type={'primary'}
              htmlType={'submit'}>
              Lưu thông tin
            </Button>
          </FormActionFooter>
        </Form>
      </Modal>
      <RuiRoNhanDienTemplate
        isVisible={isVisibleRuiRoNhanDienTemplate}
        handleCancelModal={() => setIsVisibleRuiRoNhanDienTemplate(false)}
        riskStore={riskStore}
        setRuiRoNhanDien={setRuiRoNhanDien}
        ruiRoNhanDien={ruiRoNhanDien}
        multiple={true}
        dataActions={dataActions?.tier1[0]}
        selectedRuiRoNhanDien={selectedRuiRoNhanDien}
      />
      <RuiRoNhanDienTemplatePropose
        isVisible={isVisibleRuiRoNhanDienTemplates}
        handleCancelModal={() => setIsVisibleRuiRoNhanDienTemplates(false)}
        riskStore={riskStore}
        setRuiRoNhanDienPropose={setRuiRoNhanDienPropose}
        multiple={true}
        dataActions={dataActions?.tier1[0]}
        selectedRuiRoNhanDienPropose={selectedRuiRoNhanDienPropose}
      />
      <SelectPeoplePopup
        disableSelectDepartment
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={handleCancelSelectUserDepartment}
        isVisibleSelectPeoplePopup={
          isVisibleSelectUserDepartmentPopup && isVisibleSelectUserPopup
        }
        isUserDisableList={false}
      />
    </>
  )
}

export default inject(
  'riskStore',
  'actionStore',
  'selectPeopleStore',
  'departmentStore',
  'companyStore'
)(observer(RiskIdentificationCreatePopup))
